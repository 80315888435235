import {useNavigate, useParams} from "react-router-dom";
import {EnsureInApp} from "../../components/EnsureInApp";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {WhiteBox} from "../../components/WhiteBox";

type JoinCommunityParams = { community: string }

export const JoinCommunity = () => {
    const {community} = useParams<JoinCommunityParams>()
    const next = `/join/community/${community}`
    const navigate = useNavigate()

    const onClose = () => {
        navigate("..")
    }

    return <EnsureInApp next={next}>
        <FullScreenDialog onClose={onClose} title="Rejoindre">
            <WhiteBox>
                Communauté
            </WhiteBox>
        </FullScreenDialog>
    </EnsureInApp>
}
// Import modules
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';

// Define your styled-components
const CardContainer = styled.div`
    border: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
`;

// Dynamically generate the icon container based on props
const IconContainer = styled.div<{ bgColor: string }>`
    background-color: ${props => props.bgColor};
    padding: 10px;
    color: white;
`;

const ValueContainer = styled.div`
    margin-left: 10px;
    margin-right: 10px;
`;
const SecondaryContainer = styled.div`
    font-size: 11px;
`;

// Define property types
type CardProps = {
    icon: any;
    iconBgColor: string;
    value: string | number;
    secondary?: string
};

// Create a Card component
const StatPanel: React.FC<CardProps> = ({icon, iconBgColor, value, secondary}) => {
    return (
        <CardContainer>
            <IconContainer bgColor={iconBgColor}>
                <FontAwesomeIcon icon={icon}/>
            </IconContainer>
            <ValueContainer>
                {value}
                {secondary && <SecondaryContainer>{secondary}</SecondaryContainer>}
            </ValueContainer>
        </CardContainer>
    );
}

export default StatPanel;
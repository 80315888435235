import {Outlet} from "react-router-dom";
import React from "react"
import DashboardLayout from "../components/layout/dashboard/DashboardLayout";
import {Box} from "@mui/material";
import {GroupsBottomNavigation} from "./components/GroupsBottomNavigation";

export const DashboardApp = () => {

    return <DashboardLayout>
        <Box sx={{pb: 7}} >

            <Outlet/>
            <GroupsBottomNavigation/>
        </Box>

    </DashboardLayout>
}
import {PublicInvitation} from "@profusion.media/profusion-core-group"
import {faStars} from "@fortawesome/pro-solid-svg-icons"
import {Button, IconButton, Paper, Typography} from "@mui/material"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export interface PendingInvitationsProps {
    invitations: PublicInvitation[]
}

export const PendingInvitations = (props: PendingInvitationsProps) => {
    const {invitations} = props
    const {t} = useTranslation()
    const navigate = useNavigate()
    if (invitations.length === 0) {
        return <></>
    }

    const goNext = () => {
        const next = invitations.length > 1
            ? "invitations"
            : `/invitation/${invitations[0].hid}`
        navigate(next)
    }
    return <Paper
        variant="outlined"
        elevation={3}
        style={{background: "#D0E8F2", padding: '30px', marginTop: '30px', display: 'flex', alignItems: 'center'}}>
        <IconButton edge="start" color="inherit" aria-label="icon">
            <FontAwesomeIcon icon={faStars} size="3x" color="#1565C0"/>
        </IconButton>
        <Typography variant="h6" style={{flexGrow: 1, marginLeft: '10px'}}>
            {t('home.invitation_message', {
                defaultValue: "Vous avez {{count}} invitations en attente",
                count: invitations.length
            })}
        </Typography>
        <Button onClick={goNext}
            variant="contained"
                color="primary">
            {t('home.invitation_button', {defaultValue: "voir les invitations", count: invitations.length})}

        </Button>
    </Paper>

}
import {Container, ContainerProps} from "@mui/material"

export const BodyContainer = (props: ContainerProps) => {
    const {sx, ...rest} = props

    return <Container {...rest}
                      sx={{
                          ...sx,
                          backgroundColor: "#f0f0f0",
                          height: "100vh",
                      }}
    />
}
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQrcode} from "@fortawesome/pro-light-svg-icons";
import React from "react";
import {CapacitorBarcodeScanner} from "@capacitor/barcode-scanner"
import {Html5QrcodeSupportedFormats} from "html5-qrcode";
import {useNavigate} from "react-router-dom";
import {IconButton} from "@mui/material";

export const QrScannerIcon = () => {
    const navigate = useNavigate();

    const handleOnScan = () => {
        CapacitorBarcodeScanner.scanBarcode({
            hint: Html5QrcodeSupportedFormats.QR_CODE,
        }).then(value => {
            const internal = value.ScanResult.replace("https://app.profusion.media", "")
            console.log(internal)
            navigate(internal);
        });

    }

    return <IconButton onClick={handleOnScan}>
        <FontAwesomeIcon icon={faQrcode} color="white" />
    </IconButton>

}
import {Stack} from "@mui/material";
import Container from "@mui/material/Container";
import SecurityInfo from "./components/security/SecurityInfo";
import BasicInfo from "./components/basic/BasicInfo";
import Header from "./components/Header";
import ContactInfo from "./components/contact/ContactInfo";
import {Outlet} from "react-router-dom";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import Box from "@mui/material/Box";
import {WaitingPage} from "@profusion.media/profusion-ui";


export const PersonalInfoDashboard = () => {
    const user = useConnectedUser()
    if (!user) {
        return <WaitingPage show={true} />
    }

    return <Box>
        <Stack spacing={4}>
            <Header/>
            <Container maxWidth="md" sx={{alignSelf: "center"}}>
                <BasicInfo/>
                <ContactInfo/>
                <SecurityInfo/>
                <Outlet/>
            </Container>
        </Stack>
    </Box>

}


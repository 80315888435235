import {InvitationOptionProps} from "./typings";
import {WhiteBox} from "@components/WhiteBox";
import UserSearch, {SearchResultItem} from "@components/UserSearch/UserSearch";
import {SearchResult} from "@profusion.media/graph";
import {useState} from "react";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import {Button, Stack} from "@mui/material";
import {faUser} from "@fortawesome/pro-light-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const PeopleSearchOption = (props: InvitationOptionProps) => {
    const {url, community} = props
    const [result, setResult] = useState<SearchResult | null>(null);

    const handleSelect = (v: SearchResult | null) => {
        setResult(v)
    }

    const handleClear = () => {
        setResult(null)
    }
    console.log(community)

    if (!result)
        return <WhiteBox
            icon={<FontAwesomeIcon icon={faUser}/>}
            title="Chercher une personne">
            <UserSearch onChange={handleSelect}/>
        </WhiteBox>

    return <>
        <WhiteBox title="Chercher une personne">
            <List>
                <SearchResultItem value={result} showClear onClear={handleClear}/>
            </List>
        </WhiteBox>
        <WhiteBox title="Important">
            <Stack spacing={4}>
                <Typography variant="body2">{result.displayName} aura accès à toutes les fonctionnalités
                    et contenus de cette communauté</Typography>
                <Button>Confirmer</Button>
            </Stack>
        </WhiteBox>
    </>

}
import {gql} from "@profusion.media/profusion-core";

export const ORGANIZATION_DELETE_MUTATION = gql`
    mutation organizationDelete($hid: String!) {
        deleteOrganization(hid: $hid) {
            success
            errors {
                group
                code
                field
            }
            reason
        }
    }`;
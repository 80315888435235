import React from "react";
import {createTheme, CssBaseline, ThemeProvider, Toolbar}
    from "@mui/material";
import Box from "@mui/material/Box";
import {DashboardUIProvider} from "../../../contexts/DashboardUIContext";


const mdTheme = createTheme();

interface DashboardLayoutProps {
    children: any
    drawer: any
    appBar: any

}

const DashboardLayout = (props: DashboardLayoutProps) => {
    return <ThemeProvider theme={mdTheme}>
        <DashboardUIProvider>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                {props.appBar}
                {props.drawer}
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Box  sx={{mb: 4}}>
                        {props.children as any}
                    </Box>
                </Box>
            </Box>
        </DashboardUIProvider>
    </ThemeProvider>
}

export default DashboardLayout
import {SubscriberInvitation} from "./GroupDetails/components/SubscriberInvitation/SubscriberInvitation";
import {GroupCreateConfirm} from "./GroupCreate/GroupCreateConfirm";
import {
    DetailsLoaderProps,
    loadGroup,
    loadSubscriberSettings,
    SubscriberSettingsLoaderProps
} from "./GroupDetails/loaders";
import {redirect, RouteObject} from "react-router-dom"
import {GroupSubscriberList} from "./GroupDetails/components/SubscriberList/GroupSubscriberList";
import {GroupSubscriberSettings} from "./GroupDetails/components/SubscriberSettings/GroupSubscriberSettings";
import {actionUpdate} from "./components/settings";
import {GroupSettingsUpdate} from "./GroupDetails/components/GroupSettings/GroupSettingsUpdate";
import {prayerGroupRouter} from "../Prayers/prayerRouter";
import {GroupAbout} from "./GroupDetails/components/GroupAbout/GroupAbout";
import {GroupInvitationList} from "./GroupDetails/components/SubscriberList/GroupInvitationList";
import {GroupDetails} from "./GroupDetails/GroupDetails";
import {GroupPrivacyForm} from "./GroupDetails/components/GroupPrivacy/GroupPrivacyForm";


export const groupSingleRouter: RouteObject[] = [
    {
        path: "",
        element: <GroupDetails/>,
        children: [

            {
                path: "prayer-request",
                loader: ({params}) => redirect(`/groups/${params.hid}`)
            },
            {
                path: "subscribers",
                element: <GroupSubscriberList/>,
                handle: {
                    tab: "2"
                },
            },
            {
                path: "invitations",
                element: <GroupInvitationList/>,
                id: "groupInvitationList",
                handle: {
                    tab: "3"
                },
                children: [
                    {
                        path: "add",
                        element: <SubscriberInvitation/>,
                        id: "groupInvitation"
                    },
                ]
            },
            {
                path: "settings",
                element: <GroupSubscriberSettings/>,
                loader: (props: SubscriberSettingsLoaderProps) => loadSubscriberSettings(props),
                handle: {
                    tab: "3"
                }
            },
            {
                path: "about",
                element: <GroupAbout/>,
                handle: {
                    tab: "4"
                }
            },

            ...prayerGroupRouter,
        ]
    },


    {
        path: "confirm",
        element: <GroupCreateConfirm/>,
        id: "groupCreateConfirm"
    },
    {
        path: "edit",
        element: <GroupSettingsUpdate/>,
        id: "groupSettingsUpdate",
        loader: (props: DetailsLoaderProps) => loadGroup(props),
        action: (props) => actionUpdate(props)
    },
    {
        path: "privacy",
        element: <GroupPrivacyForm/>,
        id: "groupSettingsPrivacy",

    }
]


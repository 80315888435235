import {Button, Card, CardActions, CardHeader, Stack} from "@mui/material";
import List from "@mui/material/List";
import {Group} from "@profusion.media/profusion-core-group"
import React, {ReactNode} from "react";
import {GroupAvatar} from "../../Groups/GroupDetails/components/GroupAvatar";
import {useNavigateDetails} from "../../Groups/hooks/navigation";

export interface HomeScreenGroupsProps {
    groups: Group[]
    onMore: () => void
    title: ReactNode
    empty?: ReactNode
}

export const HomeScreenGroups = (props: HomeScreenGroupsProps) => {
    const {groups, title, onMore} = props
    const navigateDetails = useNavigateDetails()

    return <Card>
        <CardHeader title={<span>{title}</span>}/>
        <List>{groups
            .slice(0, 3)
            .map((group: Group) => <Card elevation={0}
                                         key={group.hid}
                                     onClick={_ => navigateDetails(group.hid)}
                                     sx={{borderStyle: "solid", borderWidth: 1, borderColor: "#ddd"}}
            >
                <CardHeader
                    avatar={<GroupAvatar group={group}/>}
                    title={group.title}
                    subheader={group.createdBy?.displayName}
                    sx={{backgroundColor: "#f0f0f0"}}
                />
            </Card>)}</List>
        <CardActions>
            <Stack direction="row-reverse">
                <Button onClick={onMore}>Voir plus</Button>
            </Stack>
        </CardActions>
    </Card>
}
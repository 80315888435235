import {gql} from "@profusion.media/profusion-core";
import {ORGANIZATION_FIELDS} from "../fragments/organization";

export const ORGANIZATION_CREATE_MUTATION = gql`
    ${ORGANIZATION_FIELDS}
    mutation organizationCreate($organization: OrganizationCreateInput!, $parent: String) {
        createOrganization(organization: $organization, parent: $parent) {
            organization {
                ...organizationFields
            }
            errors {
                group
                field
                code
            }
        }
    }`;

export const ORGANIZATION_UPDATE_MUTATION = gql`
    ${ORGANIZATION_FIELDS}
    mutation organizationUpdate($organization: OrganizationUpdateInput!) {
        updateOrganization(organization: $organization) {
            organization {
                ...organizationFields
            }
            errors {
                group
                field
                code
            }
        }
    }`;

import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDashboardUI} from "../../../../contexts/DashboardUIContext";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPowerOff, faUser} from '@fortawesome/pro-solid-svg-icons';
import {useAuthentication, useProfusionSession} from "@profusion.media/profusion-core";


export const DrawerItems = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {toggleDrawer} = useDashboardUI()
    const session = useProfusionSession()
    const {logout} = useAuthentication()

    const openUrl = (url: string) => {
        toggleDrawer()
        navigate(url)
    }

    return <React.Fragment>

        <ListItemButton onClick={_ => openUrl("/account/personal-info/")}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faUser}/>
            </ListItemIcon>
            <ListItemText primary={t("account.menu.personal_info", "Informations personnelles")}/>
        </ListItemButton>
        <ListItemButton onClick={_ => navigate("/logout")}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faPowerOff}/>
            </ListItemIcon>
            <ListItemText primary={t("account.menu.logout", "Déconnexion")}/>
        </ListItemButton>


    </React.Fragment>
}

export const secondaryDrawerItems = (
    <React.Fragment>
        <ListSubheader component="div" inset>
            Saved reports
        </ListSubheader>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Current month"/>
        </ListItemButton>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Last quarter"/>
        </ListItemButton>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Year-end sale"/>
        </ListItemButton>
    </React.Fragment>
);
import React, {useEffect, useState} from "react"
import {Device, DeviceInfo} from "@capacitor/device"
import {WaitingPage} from "@profusion.media/profusion-ui";
import {Alert, Card, CardContent, Container, Stack} from "@mui/material";
import Link from "@mui/material/Link";
import {PublicInvitation} from "@profusion.media/profusion-core-group";
import {CapacitorCookies} from '@capacitor/core';
import {useAuthentication} from "../../../../../npm/profusion-core";
import {useLocation} from "react-router-dom";

interface AfterDLData {
    type: string
    nextUrl?: string
    invitation?: PublicInvitation
}


export const DownloadApp = () => {
    const [info, setInfo] = useState<DeviceInfo>();
    const {is_authenticated} = useAuthentication()
    const location = useLocation()

    const nextUrl = location.state.nextUrl ?? "/"

    useEffect(() => {
        const getInfo = async () => {
            const info = await Device.getInfo()
            setInfo(info)
        }
        getInfo()
    }, [])

    if (!info) return <WaitingPage show={true}/>

    const shouldShowApple = ['ios', 'windows', 'mac'].includes(info.operatingSystem)
    const shouldShowGoogle = ['android', 'windows', 'mac'].includes(info.operatingSystem)

    const goStore = async (store: "apple" | "google") => {
        const urls = {
            "apple": "https://apple.co/4bbA3p7",
            "google": "https://play.google.com/store/apps/details?id=media.profusion.app"
        }

        window.location.href = urls[store]

    }

    const goWebApp = () => {
        if (is_authenticated) {
            window.location.href = nextUrl
        } else {
            window.location.href = "/register?next="+encodeURIComponent(nextUrl)
        }
    }

    return <Container maxWidth="sm" sx={{alignSelf: "center"}}>
        <Card sx={{margin: '1em'}}>
            <CardContent>
                <div style={{textAlign: 'center'}}>

                    <img style={{width: "120px"}}
                         src="https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/2d/60/bd/2d60bd12-f082-3c2b-6c14-e71705bbe0cf/AppIcon-0-0-1x_U007emarketing-0-10-0-85-220.png/540x540bb.jpg"
                         alt="Profusion Media"/>

                    <h2>Installez notre application</h2>
                    <p>Pour une meilleure expérience, nous vous recommandons de télécharger
                        notre application mobile pour iPhone et Android.</p>

                    <Stack direction="row" spacing={2} sx={{mt: 5, mb: 5}}
                           justifyContent="center"
                           alignItems="center">
                        {shouldShowGoogle && <img height="60" onClick={() => goStore("google")}
                                                  src="https://play.google.com/intl/fr_ca/badges/static/images/badges/fr_badge_web_generic.png"
                                                  alt="Disponible sur Google Play"/>}
                        {shouldShowApple && <img height="60" onClick={() => goStore("apple")}
                                                 src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"
                                                 alt="Téléchargez depuis l'App Store"/>}
                    </Stack>

                    <Link onClick={goWebApp}>Continuer avec la version web</Link>

                </div>

            </CardContent>
        </Card>
    </Container>
}
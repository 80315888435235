import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {Organization} from "@profusion.media/graph";
import {ORGANIZATION_LIST_QUERY} from "../schema/queries/organization_list";
import {defer, LoaderFunctionArgs} from "react-router-dom";
import profusion from "../../../config/profusion";

const _loadOrganizationList = (client: ProfusionClient, page: number = 1, nb: number = 20): GraphQueryPromise<Organization[]> => {
    return executeQuery<Organization[]>(client, ORGANIZATION_LIST_QUERY, {
        isPrivate: true,
        variables: {
            page,
            nb
        }
    })
}


export const loadOrganizationList = (props: LoaderFunctionArgs<["page", "nb"]>) => {
    return defer({
        organizations: _loadOrganizationList(profusion,
            parseInt(props.params?.page ?? "1"),
            parseInt(props.params?.nb ?? "20"))
    })
}

export type LoadOrganizationListParams = {
    page: string
    nb: string
}

export type LoadOrganizationListReturn = {
    organizations: ReturnType<typeof _loadOrganizationList>,
}

import {Box, Button, Card, CardContent, CardHeader, Container, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import ReloadPrompt from "../../components/reloadPrompt/ReloadPrompt";
import React, {useEffect, useState} from "react";
import {WaitingPage} from "@profusion.media/profusion-ui";
import {useAuthentication, useProfusionSession, AuthSession} from "@profusion.media/profusion-core";
import {useNavigate} from "react-router-dom";

async function _sessionConnect(xr: string, session: AuthSession) {
    console.log("receiver : " + xr)
    await session.loginConnectToken(xr)
    console.log("receiver connected")
}

export const HomePage = () => {
    const {refresh} = useAuthentication()
    const searchParams = new URLSearchParams(window.location.search)
    const xr = searchParams.get("xr") ?? false
    const session = useProfusionSession()
    const [sessionReady, setSessionReady] = useState<boolean>(false)

    const navigate = useNavigate()

    const startSession = () => {
        setSessionReady(true)
    }

    useEffect(() => {
        if (xr) {
            _sessionConnect(xr, session).then(_ => {
                refresh().then((_: any) => {
                    startSession()
                })
            })
        } else {
            startSession()
        }
    }, [xr]);

    useEffect(() => {
        if (session.is_authenticated) {
            navigate("/groups")
        }
    }, [session.is_authenticated])


    if (!sessionReady) {
        return <WaitingPage show={true}/>
    }

    const goAbout = () => {
        window.location.href = "https://profusion.media"
    }

    const goConnect = () => {
        navigate("/login")
    }
    const goRegister = () => {
        navigate("/register")
    }

    const packageVersion = import.meta.env.VITE_APP_VERSION; // getting package version from package.json

    return <Container
        maxWidth="xl"
        sx={{
            backgroundImage: `url(/img/_0b4a91be-bfc4-4f4a-b749-de2bc9ab77dc.jpeg)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '100vh',
            overflowY: 'scroll',
            backgroundColor: 'rgba(255, 0, 0, 0.5)',  // added this line for background opacity
        }}>
        <Container maxWidth="sm" sx={{paddingY: 4}}>
            <Stack direction="column" spacing={3}>
                <img src="/icon-256x256.png"
                     width={116}
                     alt="logo profusion"/>

                <div>
                    <Typography
                        component="span"
                        variant="h3"
                        sx={{color: 'white', backgroundColor: "#bc3d1f", display: "inline"}}>
                        Profusion
                    </Typography>
                    <br/>
                    <Typography
                        component="span"
                        variant="h4"
                        sx={{color: 'white', backgroundColor: "#0e72a4", display: "inline"}}>
                        La moisson est grande, et <strong>il y a une profusion d'ouvriers</strong>
                    </Typography>
                </div>


                <Button
                    color="success"
                    onClick={goAbout}
                    variant="contained"
                    sx={{width: 256, marginY: 5}}
                >Qui sommes nous ?</Button>

                <Stack spacing={4} sx={{
                    padding: 5,
                    borderRadius:5,
                    background: "#be724099"
                }}>
                    <Button
                        color="info"
                        variant="contained"
                        onClick={goConnect}
                    >Me connecter</Button>
                    <Button
                        color="info"
                        variant="contained"
                        onClick={goRegister}
                    >Créer un compte</Button>
                    <center>
                    <a href="https://www.profusion.media/politique-de-confidentialite/"
                       target="_blank"
                       rel="noopener noreferrer"
                       style={{color: "white"}}>Politique de confidentialité</a>
                    </center>
                </Stack>
                <Typography
                    component="span"
                    variant="caption"
                    sx={{color: 'white', display: "inline"}}>
                    v{packageVersion} - &copy;2024 profusion.media (build {'__DATE__'}')
                </Typography>

            </Stack>
        </Container>
    </Container>
}
import React from 'react';
import {Card, CardContent, CardHeader} from '@mui/material';
import GridRow from "../GridRow";
import {useNavigate} from "react-router-dom";


const SecurityInfo: React.FC = () => {
    const navigate = useNavigate()

    return (
        <Card>
            <CardHeader title="Sécurité"/>
            <CardContent>
                <GridRow label="Mot de passe" value="********"
                         onEdit={() => {
                             navigate("password")
                         }}
                />
                <GridRow label="Supprimer mon compte" value=""
                         color='danger'
                         onEdit={() => {
                             navigate("/account/delete")
                         }}
                />
            </CardContent>
        </Card>
    );
}

export default SecurityInfo;

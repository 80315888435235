import * as React from 'react';
import {useState} from 'react';
import {createTheme} from '@mui/material/styles';
import {useTranslation} from "react-i18next";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {useActionData, useNavigate, useNavigation, useSearchParams, useSubmit} from "react-router-dom"
import {
    CancelButton, FieldErrorBox,
    Form,
    FormErrorBox,
    FullScreenDialog,
    PasswordInput,
    SubmitButton,
    TextInput
} from "@profusion.media/profusion-ui"
import {Card, CardActions, CardContent, Stack} from "@mui/material";
import {MutationPasswordUpdateResetArgs} from "@profusion.media/profusion-iam-user";

const theme = createTheme();

export default function PasswordUpdate() {
    const {t} = useTranslation()
    const submit = useSubmit()
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()
    const navigate = useNavigate()
    const loading = navigation.state === 'submitting'
    const [searchParams] = useSearchParams()

    const [data, setData] = useState<MutationPasswordUpdateResetArgs>({
        input: {
            code: "",
            confirmPassword: "",
            email: searchParams.get("e") ?? "",
            newPassword: ""
        }
    })

    const handleSubmit = () => {
        submit(data.input, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        navigate("..")
    }

    const onChange = (name: string, value: string) => {
        setData((prev: any) => ({...prev, input: {...prev.input, [name]: value}}))
    }

    return <FullScreenDialog title="Réinitialisation" onClose={handleCancel}>
        <Form onSubmit={handleSubmit}
              onCancel={handleCancel}
              updating={loading}
              errors={validationErrors}
        >
            <Card>
                <CardContent>
                    <Stack spacing={4}>
                        <TextInput name="email"
                                   value={data.input.email}
                                   onValueChange={onChange}
                                   label="E-mail"
                        />
                        <TextInput name="code"
                                   value={data.input.code}
                                   onValueChange={onChange}
                                   autoComplete={Date.now().toString()}
                                   label="Code"
                                   inputProps={{
                                       autoComplete: Date.now().toString()
                                   }}
                        />
                        <FieldErrorBox field="code" errorCode="CodeIncorrectError">
                            Le code saisi est incorrect.
                        </FieldErrorBox>

                        <PasswordInput name="newPassword"
                                       value={data.input.newPassword}
                                       onValueChange={onChange}
                                       autoComplete="new-password"
                                       label="Nouveau mot de passe"
                                       inputProps={{
                                           autoComplete: "new-password"
                                       }}
                        />
                        <FieldErrorBox field="newPassword" errorCode="PasswordFormatError">
                            Le nouveau mot de passe doit contenir au moins 8 caractères
                        </FieldErrorBox>
                        <PasswordInput name="confirmPassword"
                                       value={data.input.confirmPassword}
                                       onValueChange={onChange}
                                       autoComplete="new-password"
                                       label="Confirmer le mot de passe"
                                       inputProps={{
                                           autoComplete: "new-password"
                                       }}
                        />

                        <FieldErrorBox field="confirmPassword" errorCode="PasswordConfirmationError">
                            La confirmation ne correspond pas
                        </FieldErrorBox>
                    </Stack>
                </CardContent>
                <CardActions sx={{flexDirection: "row-reverse"}}>
                    <SubmitButton/>
                    <CancelButton/>
                </CardActions>
            </Card>
        </Form>
    </FullScreenDialog>
}
import {ReactNode, useEffect, useState} from "react";
import {Device, DeviceInfo} from "@capacitor/device";
import {useAuthentication} from "@profusion.media/profusion-core";
import {useNavigate} from "react-router-dom";

export interface EnsureInAppProps {
    next: string
    children: ReactNode
}
export const EnsureInApp = (props: EnsureInAppProps) => {
    const { children , next} = props
    const [info, setInfo] = useState<DeviceInfo>();
    const {is_authenticated} = useAuthentication()
    const navigate = useNavigate()

    useEffect(() => {
        const getInfo = async () => {
            const info = await Device.getInfo()
            setInfo(info)
        }
        getInfo()
    }, [])

    if (info?.platform === "web") {
        navigate("/downloadApp", {
            state: {
                next
            }
        })
        return
    }

    if (!is_authenticated) {
        navigate("/login?next=" + next)
        return
    }

    return children

}
import {gql} from "@apollo/client";
import {COMMUNITY_FRAGMENT} from "../fragments";

export const GET_COMMUNITY_DETAILS = gql`
    ${COMMUNITY_FRAGMENT}
    query getCommunityDetails ($hid: String!) {
        getCommunityDetails (hid: $hid) {
            ...COMMUNITY_FRAGMENT
        }
    }`

export const GET_COMMUNITY_CHILDREN = gql`
    ${COMMUNITY_FRAGMENT}
    query getCommunityChildren ($parentHid: String!) {
        getCommunityChildren (parentHid: $parentHid) {
            ...COMMUNITY_FRAGMENT
        }
    }`

export const GET_COMMUNITY_STATS = gql`
    query getCommunityStats ($hid: String!) {
        getCommunityStats (hid: $hid) {
            hid
            members
            activeMembers
            groups
            membersInGroups
        }
    }`
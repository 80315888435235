import profusion from "../../../config/profusion"
import {ActionFunctionArgs, redirect} from "react-router-dom"
import {executeMutation} from "@profusion.media/profusion-core";
import {
    ORGANIZATION_CREATE_MUTATION,
    ORGANIZATION_UPDATE_MUTATION
} from "../schema/mutations/organization_create_mutation";
import {
    MutationCreateOrganizationArgs,
    MutationUpdateOrganizationArgs,
    OrganizationCreate,
    OrganizationUpdate,
} from "@profusion.media/graph";
import {ORGANIZATION_LIST_QUERY} from "../schema/queries/organization_list";

export const actionOrganizationCreate = async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as MutationCreateOrganizationArgs


    console.log("RECU", data.organization)

    const response = await executeMutation<OrganizationCreate, MutationCreateOrganizationArgs>(
        profusion,
        ORGANIZATION_CREATE_MUTATION,
        data, {
            isPrivate: true,
            refetchQueries: [{
                query: ORGANIZATION_LIST_QUERY,
                context: {
                    use_private: true
                }
            }]
        })
    if (response.validationErrors) {
        return response.validationErrors
    }
    return redirect(`/su/organizations/${response.data?.organization?.hid}`)
}
export const actionOrganizationUpdate = async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as MutationUpdateOrganizationArgs
    const response = await executeMutation<OrganizationUpdate, MutationUpdateOrganizationArgs>(profusion, ORGANIZATION_UPDATE_MUTATION, {
        organization: data.organization
    }, {
        isPrivate: true
    })
    if (response.validationErrors) {
        return response.validationErrors
    }
    console.log(response)
    return redirect(`/su/organizations/${response.data?.organization?.hid}`)
}
import React, {useEffect, useState} from 'react';
import {Card, CardActions, CardContent, Stack,} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {
    CancelButton,
    Form,
    FormErrorBox,
    FullScreenDialog,
    SubmitButton,
    TextInput
} from "@profusion.media/profusion-ui";
import {RefreshRounded} from "@mui/icons-material";
import {AccountNameInput, useAccountNameUpdate, useConnectedUser} from "@profusion.media/profusion-iam-user";
import {useTranslation} from "react-i18next";

const FormUserName: React.FC = () => {
    const user = useConnectedUser()

    const {t} = useTranslation()
    const navigate = useNavigate();
    const [fields, setFields] = useState<AccountNameInput>({
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        displayName: user?.profile?.displayName ?? `${user?.firstName ?? ""} ${user?.lastName ?? ""}`
    })

    const [update, {loading: updating, data, validationErrors: errors}] = useAccountNameUpdate()

    useEffect(() => {
        if (user) {
            setFields({
                firstName: user.firstName,
                lastName: user.lastName,
                displayName: user.profile?.displayName ?? ""
            })
        }
    }, [user])

    const handleValueChange = (name: string, value: string) => {
        setFields({...fields, [name]: value})
    }

    const closeDialog = () => {
        navigate('..', {replace: true});
    }

    const handleSave = async () => {
        const r = await update({input: fields})
        if (!r.validationErrors) {
            closeDialog()
        }
    };

    const handleCancel = () => {
        // Logique d'annulation ici
        setFields({
            firstName: '',
            lastName: '',
            displayName: '',
        });
        closeDialog()
    };


    return (
        <FullScreenDialog title="Nom" onClose={closeDialog}>
            <Form onSubmit={handleSave}
                  onCancel={handleCancel}
                  errors={errors}
                  updating={updating}
                  autoClose>
                <FormErrorBox message="Des erreurs sont survenues"/>
                <Card>
                    <CardContent>
                        <Stack spacing={4}>
                            <TextInput value={fields.firstName}
                                       name="firstName"
                                       label={t("account.first_name", "Prénom")}
                                       onValueChange={handleValueChange}/>

                            <TextInput value={fields.lastName}
                                       name="lastName"
                                       label={t("account.last_name", "Nom")}
                                       onValueChange={handleValueChange}/>

                            <TextInput value={fields.displayName}
                                       name="displayName"
                                       label={t("account.display_name", "Nom affiché")}
                                       onValueChange={handleValueChange}/>
                        </Stack>

                    </CardContent>
                    <CardActions sx={{justifyContent: 'flex-end'}}>
                        <CancelButton label='Annuler'/>
                        <SubmitButton
                            activityIcon={<RefreshRounded/>}
                            iconPosition="left"
                            label="Enregistrer"/>
                    </CardActions>
                </Card>
            </Form>
        </FullScreenDialog>
    );
};

export default FormUserName;

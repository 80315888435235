import {PrayerEvent, PrayerEventGroup} from "@profusion.media/profusion-core-prayer"
import {Stack} from "@mui/material";
import {PrayerCommentList} from "./PrayerCommentList";
import {isArray} from "@apollo/client/utilities";
import {PrayerPrayedList} from "./PrayerPrayedList";
import {b} from "vite/dist/node/types.d-aGj9QkWt";

export interface PrayerEventListProps {
    events: PrayerEventGroup[]
    ignoreComment?: boolean
    flat?: boolean
}

export const PrayerEventList = (props: PrayerEventListProps) => {

    return <>
        {props.events.map(c => {
                if (isArray(c.events)) {
                    switch (c.eventGroupType) {
                        case "PRAYER":
                            return <PrayerPrayedList key={c.hid}
                                                     flat={props.flat}
                                                     prayers={c.events as PrayerEvent[]}/>

                        case "COMMENT":
                            if (!props.ignoreComment) {
                                return <PrayerCommentList key={c.hid}
                                                          comments={c.events as PrayerEvent[]}/>
                            }
                    }
                }
            }
        )}
    </>
}
import React from 'react';
import {Paper, Typography} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {styled} from '@mui/system';
import {useAccountAvatarUpdate, useConnectedUser} from "@profusion.media/profusion-iam-user";
import {useTranslation} from "react-i18next";
import {ImageUploaderDialog, UploadedImage} from "@profusion.media/profusion-ui-image-uploader";
import {AvatarWithOverlay} from "./basic/AvatarChooser";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const IconWrapper = styled(FontAwesomeIcon)`
    color: #00A8E6;
    margin-right: 1rem;
`;

const SecurityIconWrapper = styled(FontAwesomeIcon)`
    color: #3AC86E;
    margin-right: 1rem;
`;

const ContactIconWrapper = styled(FontAwesomeIcon)`
    color: #FF5C4D;
`;

const GradientPaper = styled(Paper)`
    display: flex;
    align-items: center;
    min-height: 180px;
    position: relative;
    //background: url('https://ik.imagekit.io/profusion/Static_files/Designer.jpeg?updatedAt=1709759297675&tr=w-1024,h-321,fo-custom,cm-extract');
    background: #1e7086;
    background-size: cover;
`;

const Header: React.FC = () => {

    const user = useConnectedUser()
    const [openAvatarDialog, setOpenAvatarDialog] = React.useState<boolean>(false)
    const [update, {loading: updating, validationErrors: errors}] = useAccountAvatarUpdate()
    const {t} = useTranslation()


    const handleUploaded = async (uploaded: UploadedImage) => {
        const r = await update({input: uploaded})
        if (!r.validationErrors) {
            closeDialog()
        }
    }

    function openDialog() {
        setOpenAvatarDialog(true)
    }

    function closeDialog() {
        setOpenAvatarDialog(false)
    }


    return (
        <Box sx={{paddingBottom: "64px"}}>
            <GradientPaper>
                <Container maxWidth="md" sx={{position: "relative", bottom: -84}}>
                    <Box onClick={openDialog} sx={{paddingX: 0}}>
                        <AvatarWithOverlay
                            width={128}
                            height={128}
                            imageUrl={user?.profile?.avatar?.mediumUrl ?? ""}/>
                    </Box>
                    <Box sx={{position: "absolute", top: 80, left: 154}}>
                        <Typography lineHeight={1} variant="h6">{user?.profile?.displayName}</Typography>
                    </Box>
                </Container>
            </GradientPaper>
            <ImageUploaderDialog open={openAvatarDialog}
                                 onUploaded={handleUploaded}
                                 onCancel={closeDialog}
                                 image={user?.profile?.avatar?.largeUrl ?? ""}
                                 folder="users/avatars"
                                 shape="rounded"
                                 title={t("account.avatar.label", "Photo de profil") ?? ""}
                                 filePrefix="ua-"
            />
        </Box>
    );
};

export default Header;

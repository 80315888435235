import React, {useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell as solidBell, faChevronDown} from '@fortawesome/pro-solid-svg-icons';
import {faBell as lightBell, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {
    Avatar,
    Badge,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    Paper,
    Popover,
    Snackbar
} from '@mui/material';
import {useNavigate} from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {usePushNotification} from "./PushNotificationProvider";
import {NotificationMessage} from "@profusion.media/profusion-core-notification"


export const NotificationCenter = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const [isAtBottom, setIsAtBottom] = useState(false);
    const listRef = useRef(null);
    const bellRef = useRef(null)
    const {notifications, incoming, badge, read} = usePushNotification()
    const [openSnackbar, setOpenSnackbar] = React.useState(false)

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    }
    const checkScroll = () => {
        if (listRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = listRef.current;
            console.log(scrollTop, scrollHeight, clientHeight)
            if (scrollTop + clientHeight === scrollHeight) {
                setIsAtBottom(true);
            } else {
                setIsAtBottom(false);
            }
        }
    };


    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // @ts-ignore
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;

    const actionMessage = (notification: NotificationMessage) => {
        setAnchorEl(null);
        read(notification.hid).then(_ => navigate(notification.action))
    }

    const onIncomingSnackbarAction = () => {
        setAnchorEl(bellRef.current)
    }

    return (
        <>
            <IconButton ref={bellRef} onClick={(event) => handleClick(event)}>
                <Badge color="secondary"
                       overlap="circular"
                       badgeContent={badge}
                       max={99}
                       variant="standard">
                    <FontAwesomeIcon color="#eee" icon={badge ? solidBell : lightBell}/>
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper elevation={0} sx={{p: 1, borderRadius: 0, bgcolor: '#f0f0f0'}}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row", alignItems: "center"
                    }}>
                        <Typography component="div" variant="overline">Centre de notifications</Typography>
                        <IconButton onClick={handleClose}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </IconButton>
                    </Box>
                </Paper>

                <List sx={{maxHeight: 430, overflowY: 'auto'}}
                      ref={listRef}>

                    {notifications.map((notification, index) =>
                        <ListItem key={index}
                                  sx={{backgroundColor: notification.readAt ? "#fcfcfc" : "#e3e5f3"}}
                                  onClick={() => actionMessage(notification)}>
                            {notification.avatar?.smallUrl &&
                                <ListItemAvatar>
                                    <Avatar src={notification?.avatar.smallUrl}/>
                                </ListItemAvatar>
                            }
                            <ListItemText primary={notification.title}
                                          secondary={notification.body}/>
                        </ListItem>
                    )}

                </List>
            </Popover>
            <Snackbar open={openSnackbar}
                      anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                      message="Un message vous attend !"
                      autoHideDuration={60000}
                      action={                          <Button color="secondary"
                                  size="small"
                                  onClick={onIncomingSnackbarAction}
                          >
                              VOIR
                          </Button>}
            ></Snackbar>
        </>
    )
}

import {SubscriberInvitationInput, useInvitationResend} from "@profusion.media/profusion-core-group"

import {Box, Button, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Toast} from "@capacitor/toast"
import {useNavigate} from "react-router-dom";

export interface ResendInvitationAlertProps {
    invitation: SubscriberInvitationInput
}

export const ResendInvitationAlert = (props: ResendInvitationAlertProps) => {
    const resend = useInvitationResend()
    const {t} = useTranslation()
    const navigate = useNavigate()

    const handleResend = async () => {
        await resend(props.invitation)
        await Toast.show({
            text: t("invitation.resent_success", "L'invitation a été envoyée avec succès")
        })
        navigate("..")
    }

    return <Stack direction="row-reverse" spacing={2} sx={{alignItems: "center"}}>
        <Button size="small" color="error"
                sx={{alignSelf: "flex-end"}}
                onClick={handleResend}
        >Renvoyer</Button>
        <Box>Une invitation a déjà été envoyée</Box>
    </Stack>
}
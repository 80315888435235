import {useParams, useRevalidator} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Group, SubscriberInput, SubscriberInvitationInput, useSubscriberInvitationMutation} from "@profusion.media/profusion-core-group";
import {useState} from "react";
import {CancelButton, FieldErrorBox, Form, SubmitButton, TextInput} from "@profusion.media/profusion-ui";
import {Card, CardActions, CardContent, Stack} from "@mui/material";
import {ResendInvitationAlert} from "./ResendInvitationAlert";
import {CompletedInvitationAlert} from "./CompletedInvitationAlert";

export interface SubscriberInvitationByEmailProps {
    group: Group
    onClose: () => void
}


export const SubscriberInvitationByEmail = (props: SubscriberInvitationByEmailProps) => {
    const {group, onClose} = props
    const {hid} = useParams<{ hid: string }>()
    const {t} = useTranslation()
    const [update, {loading, validationErrors}] = useSubscriberInvitationMutation()
    const [invitation, setInvitation] = useState<SubscriberInput>({
        firstName: "",
        lastName: "",
        language: group?.language ?? "fr-FR",
        message: "",
        email: "",
        phoneNumber: "",
        group: hid ?? "",
        redirectUrl: "/groups/" + hid,
    })

    const revalidator = useRevalidator()
    const handleSubmit = () => {
        update({input: invitation}).then((response: any) => {
            if (!response.validationErrors) {
                revalidator.revalidate()
                onClose()
            }
        })
    }


    const handleChangeValue = (name: string, value: string) => {
        setInvitation((prev: any) => ({...prev, [name]: value}))
    }


    return <Form onSubmit={handleSubmit}
                 onCancel={onClose}
                 updating={loading}
                 errors={validationErrors}>
        <Card>
            <CardContent>
                <Stack spacing={4}>
                    <TextInput name="firstName" label={t("invitation.first_name", "Prénom")}
                               value={invitation.firstName}
                               onValueChange={handleChangeValue}/>
                    <TextInput name="lastName" label={t("invitation.last_name", "Nom")}
                               value={invitation.lastName}
                               onValueChange={handleChangeValue}/>


                    <TextInput name="email" label={t("invitation.email", "Courriel")}
                               value={invitation.email}
                               onValueChange={handleChangeValue}/>

                    <FieldErrorBox field="email" errorCode="InvitationDuplicateException">
                        <ResendInvitationAlert invitation={invitation as SubscriberInvitationInput}/>
                    </FieldErrorBox>

                <FieldErrorBox field="email" errorCode="InvitationCompletedException">
                        <CompletedInvitationAlert />
                    </FieldErrorBox>

                </Stack>
            </CardContent>

            <CardActions sx={{justifyContent: 'flex-end'}}>
                <CancelButton/>
                <SubmitButton label="Envoyer"/>
            </CardActions>
        </Card>
    </Form>
}
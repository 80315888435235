import {useNavigate} from "react-router-dom";
import {faBookmark, faRectangleHistoryCircleUser, faUserGroup} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BottomNavigation, BottomNavigationAction, Paper} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export const GroupsBottomNavigation = () => {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()
    const {t} = useTranslation()

    const paths = [
        "/groups/mine",
        "/groups/shared",
        "/groups/favorites",
    ]

    const navigateTo = (idx: number) => {
        navigate(paths[idx])
    }

    return <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                navigateTo(newValue)
            }}
        >
            <BottomNavigationAction label={t("prayer.menu.my_groups", "Mes groupes")}
                                    icon={<FontAwesomeIcon icon={faUserGroup} fixedWidth/>}/>
            <BottomNavigationAction label={t("prayer.menu.shared_groups", "Partagés avec moi")}
                                    icon={<FontAwesomeIcon icon={faRectangleHistoryCircleUser} fixedWidth/>}/>
            <BottomNavigationAction label={t("prayer.menu.bookmarks", "Mes signets")}
                                    icon={<FontAwesomeIcon icon={faBookmark} fixedWidth/>}/>

        </BottomNavigation>
    </Paper>
}
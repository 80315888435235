import {WaitingPage} from "@profusion.media/profusion-ui";
import {useTranslation} from "react-i18next";
import profusion from "../../config/profusion";
import {useNavigate} from "react-router-dom";

export const LogoutPage = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    profusion.session.logout().then((_: any) => navigate("/"))

    return <WaitingPage show={true}
                        message={t("logout.waiting_message", "Déconnexion") ?? ""} />
}
import {gql} from "@profusion.media/profusion-core";
import {ORGANIZATION_FIELDS} from "../fragments/organization";


export const ORGANIZATION_ADMIN_QUERY = gql`
    query OrganizationAdmin($organization: String!) {
        getOrganizationAdmin (organization: $organization) {
            isPrivate
            isSystem
            hid
            name
            members {
                hid
                avatar {
                    smallUrl
                    mediumUrl
                    largeUrl
                }
                displayName
                fullName
                email
            }
        }
    }`;
import {gql} from "@profusion.media/profusion-core";
import {ORGANIZATION_FIELDS} from "../fragments/organization";


export const ORGANIZATION_LIST_QUERY = gql`
    ${ORGANIZATION_FIELDS}
    query OrganizationListQuery($page: Int, $nb: Int) {
        getOrganizations (page: $page, nb: $nb) {
            ...organizationFields
        }
    }`;
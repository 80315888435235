import {StepComponentProps} from "./StepsProps";
import {useTranslation} from "react-i18next";
import {Card, CardActions, CardContent, FormControlLabel, FormGroup, Link, Stack, Switch} from "@mui/material";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt} from '@fortawesome/pro-solid-svg-icons';
import {NextStepButton, PasswordInput, PreviousStepButton} from "@profusion.media/profusion-ui";
import React, {useState} from "react";
import Typography from "@mui/material/Typography";

export const StepConfirm = (props: StepComponentProps) => {
    const {value, onChange} = props
    const {t} = useTranslation()
    const [cgu, setCGU] = useState(false)
    const [age13, setAge13] = useState(false)

    const handleCheckAge = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAge13(event.target.checked);
    };
    const handleCheckCGU = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCGU(event.target.checked);
    };

    return <Card sx={{mt: 4}}>
        <CardContent>
            <Stack spacing={4}>
                <Typography>
                    {t("account.protect_with_password", "Protégez votre compte avec un mot de passe")}
                </Typography>
                <PasswordInput onValueChange={onChange}
                               onChange={_ => ""}
                               value={value.password}
                               name="password"
                               required
                               label={t("account.password", "Mot de passe")}
                />

                <FormGroup>
                    <FormControlLabel required control={<Switch checked={age13}
                                                                onChange={handleCheckAge}/>}
                                      label={t("account.check.age", "Je certifie avoir 13 ans ou plus")}/>

                    <Stack direction="column" spacing={1}>
                        <FormControlLabel required control={<Switch checked={cgu} onChange={handleCheckCGU}/>
                        }
                                          label={t("account.check.cgu", "j'ai pris connaissance de la politique de confidentialité")}/>
                        <Link href="https://profusion.media/politique-de-confidentialite"
                              target="_blank">
                            <FontAwesomeIcon icon={faExternalLinkAlt} fixedWidth={true}/>
                            {t("account.link.cgu", "lire la politique de confidentialité")}
                        </Link>
                    </Stack>
                </FormGroup>

            </Stack>
        </CardContent>
        <CardActions sx={{display: "flex", flexDirection: "row-reverse"}}>
            <NextStepButton
            disabled={!age13 || !cgu}
                label={t("register.steps.finish", "Créer le compte")}/>
            <PreviousStepButton/>
        </CardActions>
    </Card>
}
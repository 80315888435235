import * as React from 'react';
import {startTransition} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {useNavigate} from "react-router-dom";
import {faHome, faBookmark, faRectangleHistoryCircleUser, faUserGroup, faShield} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";

export const DashboardDrawerContent = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const user = useConnectedUser()

    return <React.Fragment>
        <ListItemButton onClick={_ => startTransition(() => {
            navigate("/groups")
        })}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faHome}/>
            </ListItemIcon>
            <ListItemText primary={t("prayer.menu.home", "Accueil")}/>
        </ListItemButton>
        <ListItemButton onClick={_ => navigate("/groups/favorites/")}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faBookmark}/>
            </ListItemIcon>
            <ListItemText primary={t("prayer.menu.bookmarks", "Mes signets")}/>
        </ListItemButton>
        <ListItemButton onClick={_ => navigate("/groups/mine")}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faUserGroup}/>
            </ListItemIcon>
            <ListItemText primary={t("prayer.menu.my_groups", "Mes groupes")}/>
        </ListItemButton>
        <ListItemButton onClick={_ => navigate("/groups/shared")}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faRectangleHistoryCircleUser}/>
            </ListItemIcon>
            <ListItemText primary={t("prayer.menu.shared_groups", "Partagés avec moi")}/>
        </ListItemButton>

        {user.isSuperuser &&
            <ListItemButton onClick={_ => navigate("/su/organizations")}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faShield}/>
                </ListItemIcon>
                <ListItemText primary={t("menu.super_admin", "Super Admin")}/>
            </ListItemButton>
        }
    </React.Fragment>
}

export const secondaryDrawerItems = (
    <React.Fragment>
        <ListSubheader component="div" inset>
            Saved reports
        </ListSubheader>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Current month"/>
        </ListItemButton>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Last quarter"/>
        </ListItemButton>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Year-end sale"/>
        </ListItemButton>
    </React.Fragment>
);
import {OrganizationTeamMember} from "@profusion.media/graph";
import List from "@mui/material/List";
import {ListItem, ListItemAvatar} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import ListItemText from "@mui/material/ListItemText";

export interface OrganizationTeamMembersProps {
    members: OrganizationTeamMember[]
}

export const OrganizationTeamMembers = (props: OrganizationTeamMembersProps) => {
    const {members} = props
    return <List>
        {members.map((m: OrganizationTeamMember) => <ListItem>
            <ListItemAvatar>
                <UserAvatar avatar={m.avatar?.smallUrl} userName={m.displayName || m.fullName || ""}/>
            </ListItemAvatar>
            <ListItemText primary={m.fullName} secondary={m.email}/>
        </ListItem>)}
    </List>
}
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {Await, useNavigate, useParams} from "react-router-dom";
import {Suspense} from "react";
import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import {PeopleSearchTabPanel} from "../tabs/PeopleSearchTabPanel";
import {Community} from "@graph";
import {QRCodeTabPanel} from "../tabs/QRCodeTabPanel";
import {PeopleSearchOption} from "./options/PeopleSearch";
import {QRCodeOption} from "./options/QRCode";
import {ShareLinkOption} from "./options/ShareLink";


type Params = {
    hid: string
    community: string
}

export const OrganizationCommunityInvitation = () => {
    const {community, stats} = useCommunityDetailsLoader("orgCommunityDetails")
    const navigate = useNavigate()

    const getUrl = (c: Community) => {
        return `https://app.profusion.media/join/community/${c.hid}`
    }

    const onClose = () => {
        navigate("..")
    }


    return <Suspense>
        <Await resolve={community}>
            {({data}) =>
                <FullScreenDialog title="Inviter dans la communauté"
                                  subtitle={data.name}
                                  onClose={onClose}>

                    <PeopleSearchOption community={data} url={getUrl(data)} />
                    <ShareLinkOption community={data} url={getUrl(data)} />
                </FullScreenDialog>
            }
        </Await>

    </Suspense>
}
import profusion from "../../config/profusion";
import {ActionFunctionArgs, redirect} from "react-router-dom"
import {actionResetPassword as _send, actionUpdateResetPassword as _update} from "@profusion.media/profusion-iam-user";

export const actionSendResetRequest = async (props: ActionFunctionArgs) => {
    const data = await props.request.json()
    const response = await _send(profusion, data.emailOrPhone)
    if (response.validationErrors) {
        return response.validationErrors
    }
    return redirect('../update-password?e='+encodeURIComponent(data.emailOrPhone))
}

export const actionUpdateResetPassword = async (props: ActionFunctionArgs) => {
    const data = await props.request.json()
    const response = await _update(profusion, data)
    if (response.validationErrors) {
        return response.validationErrors
    }
    return redirect('..')
}

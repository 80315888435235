import React, {useContext, useState} from "react";

interface DashboardUIContextProps {
    drawerOpen: boolean
    setDrawerOpen: (open: boolean) => void
    toggleDrawer: () => void

}

interface DashboardUIProviderProps {
    children: React.ReactElement
}

const DashboardUIContext = React.createContext<DashboardUIContextProps>({
    drawerOpen: false,
    setDrawerOpen: () => {
    },
    toggleDrawer: () => {
    }
})


export const DashboardUIProvider = (props: DashboardUIProviderProps) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }

    return <DashboardUIContext.Provider
        value={{
            drawerOpen,
            setDrawerOpen,
            toggleDrawer
        }}>{props.children}</DashboardUIContext.Provider>
}

export const useDashboardUI = () : DashboardUIContextProps => {
    return useContext(DashboardUIContext)
}
import React, {Suspense, useEffect, useState} from "react";
import {Await, Outlet, useLoaderData, useLocation, useNavigate, useParams} from "react-router-dom";
import {PrayerRequestListItem} from "../../PrayerRequestList/PrayerRequestListItem";
import {IconButton, Stack} from "@mui/material";
import {LoadPrayerRequestDetailsReturn} from "../../loaders";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {PrayerCommentAddButton} from "../comments/PrayerCommentAddButton";
import {useNavigateRequestEdit} from "../../hooks/navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical} from "@fortawesome/pro-light-svg-icons";
import {PrayerEventList} from "../comments/PrayerEventList";


export const PrayerRequestDetails = () => {
    const {hid, rhid} = useParams()
    const {
        prayerRequest: requestData,
        events: eventsData,
        comments: commentsData,
        overview: overviewData
    } = useLoaderData() as LoadPrayerRequestDetailsReturn
    const navigate = useNavigate()
    const [dialogTitle, setDialogTitle] = useState<string>("Loading...")
    const navigateEdit = useNavigateRequestEdit(hid ?? "")
    const location = useLocation()
    const [previousUrl, setPreviousUrl] = useState("..")

    useEffect(() => {
        setPreviousUrl(location.state?.from || "..")
    }, []);

    const handleCancel = () => {
        navigate(previousUrl)
    }

    useEffect(() => {
        requestData.then(r => {
            setDialogTitle(r.data.title)
        })
    }, [requestData]);

    return <>
        <FullScreenDialog
            slotProps={{
                dialog: {
                    disableEscapeKeyDown: true,
                    PaperProps: {
                        sx: {
                            backgroundColor: "#fafafa"
                        }

                    }
                }
            }}
            title={<Stack direction="row" useFlexGap><span>{dialogTitle}</span> <IconButton
                onClick={_ => navigateEdit(rhid ?? "")}><FontAwesomeIcon icon={faEllipsisVertical}/></IconButton>
            </Stack>}
            maxWidth="lg"
            onClose={handleCancel}>
            <Suspense>
                <Await resolve={requestData}>
                    {({data: prayerRequest}) => <PrayerRequestListItem
                        context="details"
                        prayerRequest={prayerRequest}/>}
                </Await>

            </Suspense>
            <Stack spacing={4} sx={{marginTop: 4}}>
                <PrayerCommentAddButton
                    url={`/groups/${hid}/prayer/${rhid}/comment/add`}/>
                <Suspense>
                    <Await resolve={eventsData}>
                        {({data: comments}) => <PrayerEventList events={comments}/>}
                    </Await>
                </Suspense>
            </Stack>
        </FullScreenDialog>
        <Outlet/>
    </>
}
import {GroupPrivacyInput, Group} from "@profusion.media/graph"
import {executeMutation, gql, MutationInput, MutationResult} from "@profusion.media/profusion-core";
import {GROUP_FIELDS} from "@profusion.media/profusion-core-group";
import profusion from "../../../../../config/profusion";


const GROUP_PRIVACY_MUTATION = gql`
    ${GROUP_FIELDS}
    mutation GroupPrivacy ($input: GroupPrivacyInput!) {
        groupPrivacyUpdate(input: $input) {
            ...GroupFields
        }
    }
`

export const updateGroupPrivacy = async (input: GroupPrivacyInput) => {
    return await executeMutation<Group, MutationInput<GroupPrivacyInput>>(profusion, GROUP_PRIVACY_MUTATION, {
        input: input
    }, {
        isPrivate: true
    })
}

export const useUpdateGroupPrivacy = () => {
    return (input: GroupPrivacyInput) => updateGroupPrivacy(input)
}
import {AuthSession, ProfusionClient} from "@profusion.media/profusion-core";
import i18n from "./i18n";

const profusion = new ProfusionClient(
    "g5p3oxJe1JqQVD1O",
    "k6%RzDrn1qX!4GwB#z8Y5EW%$1leO&nvX3VK6&vp?8ewAl1MRnVZg%ErXD$2#Wm0wNj4P^O/B>!QGxJk<36y7K50zqYJ!xAy>2j<07QM^rpPk?ZqRD/g0j<J8/^g&yZG",
    (lang: string) => i18n.changeLanguage(lang),
    (session: AuthSession) => {

    }
)


export default profusion
import {gql} from "@profusion.media/profusion-core";
import {ORGANIZATION_FIELDS} from "../fragments/organization";


export const ORGANIZATION_DETAILS_QUERY = gql`
    ${ORGANIZATION_FIELDS}
    query OrganizationDetails($hid: String!) {
        getOrganization (hid: $hid) {
            ...organizationFields
            mainCommunity {
                name
                description
                shortDescription
                handle
                hid
                parentHid
                isPrivate
                isSystem
            }
        }
    }`;
import {actionCreateGroup, actionUpdateGroup} from "@profusion.media/profusion-core-group";
import profusion from "../../../../config/profusion";
import {ActionFunctionArgs, redirect} from "react-router-dom"

export const actionCreate = async (props: ActionFunctionArgs) => {
    const group = await props.request.json()
    const response = await actionCreateGroup(profusion, group.input, group.avatar)
    if (response.validationErrors) {
        return response.validationErrors
    }
    return redirect(`/groups/${response.data?.group?.hid}/confirm`)
}
export const actionUpdate = async (props: ActionFunctionArgs) => {
    const group = await props.request.json()
    const response = await actionUpdateGroup(profusion, group.input, group.avatar)
    if (response.validationErrors) {
        return response.validationErrors
    }
    console.log(response)
    return redirect(`/groups/${response.data?.group?.hid}`)
}
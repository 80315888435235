import React, {useEffect} from "react"
import {
    actionNotificationRead,
    loadNotificationMessages,
    NotificationMessage
} from "@profusion.media/profusion-core-notification"
import profusion from "../../config/profusion";
import {ActionPerformed, PushNotifications, PushNotificationSchema} from "@capacitor/push-notifications";

import {useAuthentication} from "@profusion.media/profusion-core";
import {Badge} from "@capawesome/capacitor-badge";

export interface PushNotificationContextProps {
    notifications: Array<NotificationMessage>
    incoming: boolean
    badge: number
    newIncoming: number
    read: (hid: string) => Promise<any>
}


export const PushNotificationContext = React.createContext<PushNotificationContextProps>({
    notifications: [],
    incoming: false,
    badge: 0,
    newIncoming: 0,
    read: (hid: string) => Promise.resolve()
});

interface PushNotificationProviderProps {
    children: React.ReactNode | React.ReactNode[];
    onAction: (action: string) => void
}

interface FetchMessageResponse {
    notifications: NotificationMessage[]
    badge: number
    newIncoming: number
}

const fetchMessages = async () : Promise<FetchMessageResponse> => {
    console.log("REFETCH NOTIFICATION CENTER")
    let countNew = 0
    let notifications: NotificationMessage[] = []
    return loadNotificationMessages(profusion).then(({data}) => {
        notifications = data
        data.forEach((item: NotificationMessage) => {
            if (!item.readAt) {
                countNew++
            }
        })
        console.log("NOTIFICATION CENTER UNREAD: ", countNew)
        Badge.set({
            count: countNew
        })
        return Promise.resolve({notifications, badge: countNew, newIncoming:0, incoming: false})
    })
}

interface PushNotificationWatcherProps {
    onFetch: (data: FetchMessageResponse) => void
}

const PushNotificationWatcher = (props: PushNotificationWatcherProps) => {
    const [timeoutId, setTimeoutId] = React.useState<number>(0)
    const {is_authenticated} = useAuthentication()
    useEffect(() => {
        console.log("PUSH NOTIF IS_AUTH", is_authenticated)
        if (is_authenticated && !timeoutId) {
            setTimeoutId(setInterval(() => fetchMessages().then(props.onFetch), 60000));
            fetchMessages().then(props.onFetch)
        }
        return () => {
            console.log("CLEARING TIMEOUT FETCH NC")
            clearTimeout(timeoutId);
        }
    }, [is_authenticated])
    return <></>
}

export const PushNotificationProvider = (props: PushNotificationProviderProps) => {
    const read = async (hid: string) => {
        await actionNotificationRead(profusion, [hid])
        await fetchMessages().then(updateState)
    }

    const updateState = (data: FetchMessageResponse, incoming = false) => {
        setState(prev => ({
            ...prev,
            notifications: data.notifications,
            badge: data.badge,
            newIncoming: data.newIncoming,
            incoming
        }))
    }

    useEffect(() => {
        try {
            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotificationSchema) => {
                    fetchMessages().then(data => {
                        updateState(data, true)
                    })
                }
            );

            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification: ActionPerformed) => {
                    if (notification.notification.data?.action) {
                        props.onAction(notification.notification.data.action)
                    }
                }
            );
        } catch (e) {
            console.log("SKIP PUSH LISTENER")
        }

    }, []);


    const [state, setState] = React.useState<PushNotificationContextProps>({
        notifications: [],
        incoming: false,
        badge: 0,
        newIncoming: 0,
        read
    })

    return <PushNotificationContext.Provider value={state}>
        <PushNotificationWatcher onFetch={updateState} />
        {props.children}

    </PushNotificationContext.Provider>;
}

export const usePushNotification = (): PushNotificationContextProps => {
    return React.useContext(PushNotificationContext)
}

import React from 'react';
import {Card, CardContent, CardHeader} from '@mui/material';
import GridRow from "../GridRow";
import {useNavigate} from "react-router-dom";
import {useAccountAvatarUpdate, useConnectedUser} from "@profusion.media/profusion-iam-user";
import {AvatarWithOverlay} from "./AvatarChooser";
import {ImageUploaderDialog, UploadedImage} from "@profusion.media/profusion-ui-image-uploader";
import {getLanguageDef} from "@profusion.media/profusion-core"
import {useTranslation} from "react-i18next";

const BasicInfo: React.FC = () => {
    const navigate = useNavigate()
    const user = useConnectedUser()
    const [openAvatarDialog, setOpenAvatarDialog] = React.useState<boolean>(false)
    const [update, {loading: updating, validationErrors: errors}] = useAccountAvatarUpdate()
    const {t} = useTranslation()


    const handleUploaded = async (uploaded: UploadedImage) => {
        const r = await update({input: uploaded})
        if (!r.validationErrors) {
            closeDialog()
        }
    }

    function openDialog() {
        setOpenAvatarDialog(true)
    }

    function closeDialog() {
        setOpenAvatarDialog(false)
    }

    return (
        <Card>
            <CardHeader title={t("account.basic_info.title", "Informations générales")}
                        subheader={t("account.basic_info.explain", "Informations pouvant être visibles par les autres utilisateurs")}
            />
            <CardContent>

                <GridRow label={t("account.personal_info.name.label","Nom")}
                         value={user?.firstName + " " + user?.lastName} onEdit={() => {
                    navigate("basic-info")
                }}/>
                <GridRow label={t("account.personal_info.dateOfBirth.label","Date de naissance")}
                         value={user?.dateOfBirth?.toString() ?? ""}
                         onEdit={() => {
                             navigate("birthdate-info")
                         }}/>
                <GridRow label={t("account.personal_info.homeTown.label","Lieu de résidence")}
                         value={user?.address?.city ?? ""} onEdit={() => {
                    navigate("address-info")
                }}/>
                <GridRow label={t("account.personal_info.language.label","Langue")}
                         value={getLanguageDef(user.language ?? "fr-FR").label} onEdit={() => {
                    navigate("language-info")
                }} />
            </CardContent>

        </Card>
    );
}

export default BasicInfo;

import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {OrganizationTeam, QueryGetOrganizationAdminArgs} from "@profusion.media/graph";
import {defer, LoaderFunctionArgs} from "react-router-dom";
import profusion from "../../../config/profusion";
import {ORGANIZATION_ADMIN_QUERY} from "../schema/queries/organization_admin";

const _loadOrganizationTeam = (client: ProfusionClient, hid: string): GraphQueryPromise<OrganizationTeam> => {
    return executeQuery<OrganizationTeam, QueryGetOrganizationAdminArgs>(client, ORGANIZATION_ADMIN_QUERY, {
        isPrivate: true,
        variables: {
            organization: hid
        }
    })
}


export const loadOrganizationTeam = (props: LoaderFunctionArgs<["hid"]>) => {
    return defer({
        team: _loadOrganizationTeam(profusion, props.params.hid ?? "")
    })
}

export type LoadOrganizationDetailsParams = {
    hid: string
}

export type LoadOrganizationTeamReturn = {
    team: ReturnType<typeof _loadOrganizationTeam>,
}

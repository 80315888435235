import {Await, Outlet, useLoaderData, useNavigate} from "react-router-dom";
import {LoadOrganizationTeamReturn} from "../../loaders/oraganizationTeamLoader";
import {Suspense} from "react";
import {Box, Button} from "@mui/material";
import {OrganizationTeamEmpty} from "./OrganizationTeamEmpty";
import {OrganizationTeamMembers} from "./OrganizationTeamMembers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/pro-regular-svg-icons"

export const OrganizationTeamDetails = () => {
    const {team} = useLoaderData() as LoadOrganizationTeamReturn
    const navigate = useNavigate()


    const openInvite = () => {
        navigate("invite")
    }

    return <Suspense>
        <Await resolve={team}>
            {({data}) => <Box>
                {data.members && <OrganizationTeamMembers members={data.members}/>}
                {!data.members.length && <OrganizationTeamEmpty team={data}/>}
                <Button
                    onClick={openInvite}
                    startIcon={<FontAwesomeIcon icon={faUserPlus}/>}>Inviter</Button>
                <Outlet/>
            </Box>}
        </Await>
    </Suspense>
}
import StyledAppBar from "../../../../components/layout/dashboard/appbar/SyledAppBar";
import {useDashboardUI} from "../../../../contexts/DashboardUIContext";
import {IconButton, Toolbar, Typography} from "@mui/material";
import {useAuthentication, useSsoTokenCreate} from "@profusion.media/profusion-core"
import MenuIcon from '@mui/icons-material/Menu';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPrayingHands} from '@fortawesome/pro-solid-svg-icons';
import {useNavigate} from "react-router-dom";

const DashboardAppBar = () => {
    const {drawerOpen, toggleDrawer} = useDashboardUI()
    const ssoTokenCreate = useSsoTokenCreate()
    const {logout} = useAuthentication()
    const navigate = useNavigate()

    function goPrayerApp() {
        navigate("/groups")
    }

    return <StyledAppBar position="absolute" open={drawerOpen}>
        <Toolbar
            sx={{
                pr: '24px', // keep right padding when drawer closed
            }}
        >
            <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                    marginRight: '36px',
                    ...(drawerOpen && {display: 'none'}),
                }}
            >
                <MenuIcon/>
            </IconButton>
            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{flexGrow: 1}}
            >
                Mon compte
            </Typography>
            <IconButton color="inherit" onClick={_ => goPrayerApp()}>

                <FontAwesomeIcon icon={faPrayingHands}/>

            </IconButton>

        </Toolbar>
    </StyledAppBar>
}

export default DashboardAppBar
import {Outlet} from "react-router-dom"

import DashboardLayout from "../../components/layout/dashboard/DashboardLayout";
import ErrorPage from "../errors/ErrorPage";
import DashboardDrawer from "./components/drawer/DashboardDrawer";
import DashboardAppBar from "./components/appbar/DashboardAppBar";
import {PersonalInfoRoutes} from "../PersonalInfo/PersonalInfoRouter";

const AccountLayout = () => <DashboardLayout drawer={<DashboardDrawer/>}
                                             appBar={<DashboardAppBar/>}>
    <Outlet/>
</DashboardLayout>


const AccountRouter = [
    {
        path: "",
        element: <AccountLayout/>,
        errorElement: <ErrorPage/>,
        children: [
            ...PersonalInfoRoutes,
        ]
    }
]

export default AccountRouter
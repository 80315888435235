import {Await, useLoaderData, useNavigate, useParams} from "react-router-dom";
import {LoadOrganizationListParams, LoadOrganizationListReturn} from "../../loaders/organizationListLoader";
import {Suspense} from "react";
import List from "@mui/material/List";
import {ListItem, ListItemAvatar, Paper} from "@mui/material";
import {Organization} from "@profusion.media/graph";
import {UserAvatar} from "@profusion.media/profusion-ui";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

export const OrganizationList = () => {
    const {page, nb} = useParams<LoadOrganizationListParams>()
    const {organizations} = useLoaderData() as LoadOrganizationListReturn
    const navigate = useNavigate()

    const handleDetails = (hid: string) => {
        navigate(`/su/organizations/${hid}`)
    }

    return <Suspense>
        <Paper sx={{p:2}}>
            <Typography variant="h6" >Organisations</Typography>
        </Paper>
        <Await resolve={organizations}>
            {({data}) => <List>
                {data.map((organization: Organization) => <ListItem key={organization.hid} onClick={() => handleDetails(organization.hid || "")}>
                    <ListItemAvatar>
                        <UserAvatar avatar={organization.profile?.avatar?.smallUrl} userName={organization.name}/>
                    </ListItemAvatar>
                    <ListItemText primary={organization.name}/>
                </ListItem>)}
            </List>}
        </Await>
    </Suspense>
}
import FormUserName from "./components/basic/FormUserName";
import FormUserAddress from "./components/basic/FormUserAddress";
import FormContact from "./components/contact/FormContact";
import {PersonalInfoDashboard} from "./PersonalInfoDashboard";
import ErrorPage from "../errors/ErrorPage";
import FormBirthdate from "./components/basic/FormBirthdate";
import FormLanguage from "./components/basic/FormLanguage";
import FormPassword from "./components/security/FormPassword";
import {actionUpdatePassword} from "./actions/password_update";
import {RouteObject} from "react-router-dom"


export const PersonalInfoRoutes: RouteObject[] = [{
    path: "personal-info",
    element: <PersonalInfoDashboard/>,
    errorElement: <ErrorPage/>,
    children: [
        {
            path: "basic-info",
            element: <FormUserName/>,
        },
        {
            path: "contact-info",
            element: <FormContact/>,
        },
        {
            path: "address-info",
            element: <FormUserAddress/>,
        },
        {
            path: "birthdate-info",
            element: <FormBirthdate/>,
        },
        {
            path: "language-info",
            element: <FormLanguage/>,
        },
        {
            path: "password",
            element: <FormPassword/>,
            action: (props) => actionUpdatePassword(props)
        },
    ]
}]

import {Group} from "@profusion.media/profusion-core-group"
import {IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShare} from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { Share } from '@capacitor/share';


export interface GroupAppBarShareProps {
    group: Group
}

export const GroupAppBarShare = (props: GroupAppBarShareProps) => {
    const {group} = props

    if (group.folder !== "own") {
        return
    }

    const handleShare = async () => {
        await Share.share({
            title: group.title,
            text: 'Rejoins mon groupe maintenant !',
            url: `https://app.profusion.media/groups/${group.hid}`,
            dialogTitle: 'Partager le groupe',
        });
    }

    return <IconButton onClick={handleShare}>
        <FontAwesomeIcon color="#f0f0f0" icon={faShare}/>
    </IconButton>
}
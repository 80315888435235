import {Box, ClickAwayListener, Fade, Paper, Popper, PopperPlacementType, styled} from "@mui/material";
import React, {ReactElement} from "react";

interface Props {
    children: ReactElement;
    anchor: any
    open: boolean;
    onClose?: () => void;
    arrow?: boolean;
    placement?: PopperPlacementType;
}


interface StyledPopperProps {
    backgroundColor?: string;
}

export const Arrow = styled('div')(({theme}) => ({
    color: "#fafafa",
    overflow: 'hidden',
    position: 'absolute',
    width: '1em',
    height: '0.71em',
    top: 0,
    left: 0,
    marginTop: "-0.71em",
    marginLeft: 4,
    marginRight: 4,
    boxSizing: 'border-box',
    zIndex: 6,
    '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        backgroundColor: 'currentColor',
        transform: 'rotate(45deg)',
        transformOrigin: "0 100%"
    },
}))

const StyledRichPopper = styled(Popper)<StyledPopperProps>(({theme, backgroundColor}) => ({
    backgroundColor: backgroundColor || '#f0f0f5', // Utilisation du paramètre backgroundColor ou une valeur par défaut
    padding: "0px",
    borderRadius: 5,
    color: "#454545",
    zIndex: 5,
    [`& ${Arrow}`]: {
        top: 0,
        left: 0,
        marginTop: "-0.71em",
        marginLeft: 4,
        marginRight: 4
    },
    [`& ${Arrow}::before`]: {
        transformOrigin: "0 100%"
    },
    '&[x-placement*="top"] ${Arrow}': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.71em",
        marginLeft: 4,
        marginRight: 4,
        "&::before": {
            transformOrigin: "100% 0"
        }
    },
    '&[x-placement*="right"] ${Arrow}': {
        left: 0,
        marginLeft: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 4,
        marginBottom: 4,
        "&::before": {
            transformOrigin: "100% 100%"
        }
    },
    '&[x-placement*="left"] ${Arrow}': {
        right: 0,
        marginRight: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 4,
        marginBottom: 4,
        "&::before": {
            transformOrigin: "0 0"
        }
    }
}));


export const RichPopper = ({
                               placement = "top",
                               arrow = true,
                               open,
                               onClose = () => {
                               },
                               anchor,
                               children
                           }: Props) => {
    const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);

    return (
        <div>
            <StyledRichPopper
                open={open}
                anchorEl={anchor}
                placement={placement}
                transition
                modifiers={[
                    {
                        name: "arrow",
                        enabled: true,
                        options: {
                            element: arrowRef
                        }
                    }
                ]}
            >
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <ClickAwayListener onClickAway={onClose}>
                                <Paper>
                                    {arrow ? (
                                        <Arrow ref={setArrowRef}/>
                                    ) : null}
                                    <Box>{children as any}</Box>
                                </Paper>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </StyledRichPopper>
        </div>
    );
};


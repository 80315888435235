import {FullScreenDialog} from "@profusion.media/profusion-ui";
import React, {startTransition, Suspense} from "react";
import {Await, useLoaderData, useNavigate, useParams, useRouteLoaderData} from "react-router-dom";
import {LoadPrayerRequestDetailsReturn} from "../../loaders";
import {PrayerEventList} from "../comments/PrayerEventList";

export const PrayerStatsDialog = () => {
    const {hid, rhid} = useParams()
    const {
        prayerRequest: requestData,
        events: eventsData,
        comments: commentsData,
        overview: overviewData
    } = useRouteLoaderData("groupPrayerRequestDetails") as LoadPrayerRequestDetailsReturn

    const navigate = useNavigate()

    const handleCancel = () => {
        startTransition(() => {
            navigate(-1)
        })
    }

    return (
        <FullScreenDialog title="Statistiques"
                          onClose={handleCancel}>
            <Suspense>
                <Await resolve={eventsData}>
                    {({data: comments}) => <PrayerEventList events={comments} ignoreComment flat />}
                </Await>
            </Suspense>
        </FullScreenDialog>
    );
}
import {StepComponentProps} from "./StepsProps";

import {NextStepButton, PhoneInput, TextInput, FieldErrorBox} from "@profusion.media/profusion-ui";
import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Card, CardActions, CardContent, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const StepIdentity = (props: StepComponentProps) => {
    const {value, onChange} = props
    const {t} = useTranslation()

    return <Card sx={{mt: 4}}>
        <CardContent>
            <Stack spacing={4}>
                <TextInput value={value.firstName}
                           name="firstName"
                           required
                           label={t("account.first_name", "Prénom")}
                           onValueChange={onChange}/>

                <TextInput value={value.lastName}
                           name="lastName"
                           required
                           label={t("account.last_name", "Nom")}
                           onValueChange={onChange}/>

                <TextInput value={value.email}
                           name="email"
                           type="email"
                           required
                           label={t("account.email", "E-mail")}
                           onValueChange={onChange}/>

                <FieldErrorBox field="email" errorCode="DuplicateValue">
                    {t("account.email_exists", "Un compte existe déjà avec cette adresse")}
                </FieldErrorBox>

                <PhoneInput value={value.phoneNumber ?? ""}
                            name="phoneNumber"
                            label={t("account.phoneNumber", "Numéro de cellulaire")}
                            onValueChange={onChange}/>



                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>{t("account.registration.invitation_code", "Avez-vous reçu un code d'invitation ?")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <TextInput value={value.invitationCode}
                                   name="invitationCode"
                                   label={t("account.invitation_code", "Code d'invitation")}
                                   onValueChange={onChange}/>
                    </AccordionDetails>
                </Accordion>


            </Stack>
        </CardContent>
        <CardActions sx={{flexDirection: "row-reverse", display: "flex"}}>
            <NextStepButton/>
        </CardActions>
    </Card>
}
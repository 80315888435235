import {
    GroupOrigin,
    loadGroupList,
    loadGroupDetails,
    loadPendingInvitations
} from "@profusion.media/profusion-core-group";
import {defer} from "react-router-dom"
import profusion from "../../../config/profusion";
import {loadPinnedRequestList as _loaderPinned} from "@profusion.media/profusion-core-prayer"

export type LoadListReturn = {
    groups: ReturnType<typeof loadGroupList>,
    what: GroupOrigin
}

export const loadList = (what: GroupOrigin) => {
    return defer({groups: loadGroupList(profusion, what), what})
}


export const loadHomeData = () => {
    return defer({
        shared: loadGroupList(profusion, "shared"),
        mine: loadGroupList(profusion, "mine"),
        favorites: _loaderPinned(profusion),
        pendingInvitations: loadPendingInvitations(profusion),
    })
}

export type loadHomeDataReturn = {
    shared: ReturnType<typeof loadGroupList>,
    mine: ReturnType<typeof loadGroupList>,
    favorites: ReturnType<typeof _loaderPinned>,
    pendingInvitations: ReturnType<typeof loadPendingInvitations>,

}

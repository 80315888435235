import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import {Suspense} from "react";
import {Await, Outlet, useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {FullScreenDialog} from "@profusion.media/profusion-ui";

export const OrganizationCommunity = () => {
    const {community, stats} = useCommunityDetailsLoader("orgCommunityDetails")
    const navigate = useNavigate()

    const handleInvite = () => {
        navigate("invite")
    }

    const handleClose = () => {
        navigate("..")
    }

    return <Suspense>
        <Await resolve={community}>
            {({data}) => <FullScreenDialog title={data.name} onClose={handleClose}>
                <Button onClick={handleInvite}>Inviter</Button>
                {data.name}
                <Outlet/>
            </FullScreenDialog>}
        </Await>
    </Suspense>
}
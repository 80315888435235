import {FullScreenDialog, HeadsUp} from "@profusion.media/profusion-ui";
import {Await, useNavigate, useRouteLoaderData} from "react-router-dom";
import SearchAutocomplete, {SearchResultItem} from "../../../../components/UserSearch/UserSearch";
import {LoadOrganizationTeamReturn} from "../../loaders/oraganizationTeamLoader";
import {Suspense, useState} from "react";
import {Box, Button, Stack} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserSlash} from "@fortawesome/pro-regular-svg-icons";
import Typography from "@mui/material/Typography";
import {WhiteBox} from "../../../../components/WhiteBox";
import {SearchResult} from "@profusion.media/graph"
import List from "@mui/material/List";

export const OrganizationTeamInvite = () => {
    const navigate = useNavigate()
    const {team} = useRouteLoaderData("organizationAdmin") as LoadOrganizationTeamReturn
    const [selected, setSelected] = useState<SearchResult>()

    const handleClose = () => {
        navigate('..')
    }

    return <Suspense>
        <Await resolve={team}>
            {({data}) => <FullScreenDialog title="Inviter un administrateur" onClose={handleClose}>
                {!selected && <WhiteBox>
                    <SearchAutocomplete onChange={(v) => setSelected(v || undefined)}/>
                    <Box marginTop={3}>
                        <HeadsUp>
                            <FontAwesomeIcon icon={faUserSlash} fixedWidth/>
                            <Typography>
                                Vous ne trouvez pas l'utilisateur dans la liste ?
                                Essayer une autre méthode d'invitation
                            </Typography>
                        </HeadsUp>
                    </Box>
                </WhiteBox>}

                {selected && <WhiteBox>
                    <Stack spacing={3}>
                        <Typography variant="h5">Inviter {selected.displayName}</Typography>

                        <List>
                            <SearchResultItem value={selected} showClear onClear={() => setSelected(undefined)}/>
                        </List>

                        <Typography variant="body2">Une fois l'invitation acceptée, {selected.displayName} apparaîtra
                            dans la liste des administrateurs.</Typography>

                        <Box>
                            <Stack direction="row" spacing={2}>
                                <Button color="inherit"
                                        variant="outlined"
                                        onClick={() => setSelected(undefined)}
                                >Annuler</Button>

                                <Button color="primary" variant="contained">Confirmer</Button>
                            </Stack>
                        </Box>
                    </Stack>
                </WhiteBox>}

            </FullScreenDialog>}
        </Await>
    </Suspense>
}

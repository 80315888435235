import React from "react";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {Box, IconButton, Toolbar} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft, faChevronCircleRight} from "@fortawesome/pro-light-svg-icons";
import {Capacitor} from "@capacitor/core";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {Document, Page, pdfjs} from "react-pdf"
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

export const TestPdfReader = () => {
    const [loading, setLoading] = React.useState(true);
    const [localFile, setLocalFile] = React.useState<any>()
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState<number>(0)
    const [initialWidth, setInitialWidth] = React.useState(300);

    const wrapperRef = React.useRef<any>(null)

    const setPdfSize = () => {
        if (wrapperRef && wrapperRef.current) {
            setInitialWidth(wrapperRef.current.getBoundingClientRect().width);
        }
    };

    React.useEffect(() => {
        window.addEventListener('resize', setPdfSize);
        setPdfSize();
        return () => {
            window.removeEventListener('resize', setPdfSize);
        };
    }, []);

    React.useEffect(() => {
        if (!localFile) {
            Filesystem.requestPermissions().then(status => {
                Filesystem.downloadFile({
                    url: "https://api.profusion.media/static/library/jsd-test.pdf",
                    path: "jsd-test.pdf",
                    directory: Directory.Data,
                }).then(r => {
                    if (r.blob) {
                        setLocalFile(r.blob)
                        setLoading(false);
                    } else {
                        Filesystem.getUri({
                            path: "jsd-test.pdf",
                            directory: Directory.Data,
                        }).then(({uri}) => {
                            setLocalFile(Capacitor.convertFileSrc(uri))
                            setLoading(false);
                        })
                    }
                })
            });
        }
    }, [])


    if (loading) return <p> LOADING...</p>

    console.log(localFile)

    return <Box height="100vh" display="flex" flexDirection="column">

        <Toolbar>
            <IconButton onClick={() => setPage(p => p - 1)}><FontAwesomeIcon icon={faChevronCircleLeft}/></IconButton>
            <div>
                <input value={page} size={3}/> / {totalPages}
            </div>
            <IconButton onClick={() => setPage(p => p + 1)}><FontAwesomeIcon icon={faChevronCircleRight}/></IconButton>
        </Toolbar>

        <Box width="100vw" ref={wrapperRef}>
            <Document file={localFile} onLoadSuccess={(d) => {
                setPdfSize()
                setTotalPages(d.numPages)
            }}>

                <TransformWrapper>
                    <TransformComponent>
                        <Page pageNumber={page} width={initialWidth}/>

                    </TransformComponent>
                </TransformWrapper>
            </Document>
        </Box>
    </Box>
}
import {gql} from "@profusion.media/profusion-core";


export const ORGANIZATION_COMMUNITIES_QUERY = gql`
    fragment CommunityFields on Community {
        hid
        isSystem
        isPrivate
        name
        shortDescription
        description
        handle
        language
        parentHid
        color
    }
    query OrganizationDetails($hid: String!) {
        getOrganizationCommunities (hid: $hid) {
            hid
            isDefault
            roles            
            community {
                ...CommunityFields
            }
            children {
                ...CommunityFields
            }
        }
    }`;
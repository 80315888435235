import {Organization, OrganizationCreateInput, OrganizationUpdateInput} from "@profusion.media/graph"

export const emptyOrganizationInput : OrganizationCreateInput|OrganizationUpdateInput = {
    address: {
        street: "",
        city: "",
        province: "",
        postalCode: "",
        country: "",
        displayName: "",
        visibility: "public",
    },
    name: "",
    shortName: "",
    leaderLastName: "",
    leaderFirstName: "",
    email: "",
    phoneNumber: "",
    website: "",
    communityHandle: "",
}
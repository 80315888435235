import React from "react"
import {Box, ToggleButton, ToggleButtonGroup} from '@mui/material';
import Typography from "@mui/material/Typography";

const organizationTypes = [
    {
        id: 'church',
        label: 'Église'
    }, {
        id: 'denomination',
        label: "Dénomination"
    }, {
        id: 'partner',
        label: "Ministère"
    }, {
        id: 'provider',
        label: "Fournisseur"
    }]


export interface OrganizationTypeChooserProps {
    value: string
    onChange: (v: string) => void
}

export const OrganizationTypeChooser = (props: OrganizationTypeChooserProps) => {
    return (


            <Box marginBottom={1} padding={2} bgcolor="#fff">
                <Typography variant="overline">Type</Typography>
                <Box>
                <ToggleButtonGroup
                    value={props.value}
                    exclusive
                    onChange={(event, newType) => {
                        if (newType !== null) {
                            props.onChange(newType);
                        }
                    }}
                    aria-label="organization type"
                >
                    {organizationTypes.map((type) => (
                        <ToggleButton value={type.id} key={type.id} aria-label={type.label} color="primary">
                            {type.label}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>
        </Box>
    );
}

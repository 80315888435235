import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {Organization, OrganizationCommunity} from "@profusion.media/graph";
import {defer, LoaderFunctionArgs, useRouteLoaderData} from "react-router-dom";
import profusion from "@config/profusion";
import {ORGANIZATION_DETAILS_QUERY} from "../schema/queries/organization_details";
import {ORGANIZATION_COMMUNITIES_QUERY} from "../schema/queries/organization_communitites";

const _loadOrganizationDetails = (client: ProfusionClient, hid: string): GraphQueryPromise<Organization> => {
    return executeQuery<Organization>(client, ORGANIZATION_DETAILS_QUERY, {
        isPrivate: true,
        variables: {
            hid
        }
    })
}

const _loadOrganizationCommunities = (client: ProfusionClient, hid: string) : GraphQueryPromise<OrganizationCommunity[]> => {
    return executeQuery<OrganizationCommunity[]>(client, ORGANIZATION_COMMUNITIES_QUERY, {
        isPrivate: true,
        variables: {
            hid
        }
    })
}

const loadData = (hid: string) => {
    return {
        organization: _loadOrganizationDetails(profusion, hid),
        communities: _loadOrganizationCommunities(profusion, hid)
    }
}


export const loadOrganizationDetails = (props: LoaderFunctionArgs<["hid"]>) => {
    return defer(loadData(props.params.hid ?? ""))
}

export const useOrganizationDetailsLoader = (routeId: string) => {
    return useRouteLoaderData(routeId) as ReturnType<typeof loadData>
}

export type OrganizationLoaderReturn = ReturnType<typeof loadData>
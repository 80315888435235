import {useAuthentication} from "@profusion.media/profusion-core";
import {Outlet, useNavigate} from "react-router-dom";

export const GroupAccessChecker = () => {
    const {is_authenticated} = useAuthentication()
    const navigate = useNavigate()

    if (is_authenticated) {
        return <Outlet />
    } else {
        navigate("public")
    }
}
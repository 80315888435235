import {useTranslation} from "react-i18next";
import {DateTime} from "luxon";
import Typography, {TypographyProps} from "@mui/material/Typography";
import * as React from "react";

const CopyrightLine = (props: TypographyProps) => {
    const {t} = useTranslation()
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {t("copyright.profusion", {defaultValue: '©{{ year }} - Profusion Media', year: DateTime.now().year})}
        </Typography>
    );
}

export default CopyrightLine
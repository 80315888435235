import {useNavigate, useRouteLoaderData} from "react-router-dom";
import {LoadDetailsReturn} from "../../loaders";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {useTranslation} from "react-i18next";
import {Box, Button, FormControlLabel, List, ListItem, Paper, Radio, Stack} from "@mui/material";
import React, {useEffect, useState} from 'react'; // Include useState
import {Group, GroupGroupModelJoinPolicyChoices, GroupGroupModelVisibilityChoices} from "@profusion.media/graph"
import Typography from "@mui/material/Typography";
import {useUpdateGroupPrivacy} from "./action"

export const GroupPrivacyForm = () => {
    const {group: groupData} = useRouteLoaderData("groupDetails") as LoadDetailsReturn
    const {t} = useTranslation()
    const navigate = useNavigate()
    const update = useUpdateGroupPrivacy()
    const [data, setData] = useState<Group>()

    useEffect(() => {
        groupData.then(({data}) => {
            setData(data)
        })
    }, [groupData])

    const visibilityChoices = [
        {
            value: GroupGroupModelVisibilityChoices.Private,
            label: t("group.visibility.private", "Uniquement les participants")
        },
        {
            value: GroupGroupModelVisibilityChoices.Restricted,
            label: t("group.visibility.restricted", "Uniquement les membres de ma communauté")
        },
        {
            value: GroupGroupModelVisibilityChoices.Public,
            label: t("group.visibility.public", "Tout le monde")
        },
    ]

    const joinChoices = [
        {
            value: GroupGroupModelJoinPolicyChoices.Link,
            label: t("group.join.link", "Toute personne disposant du lien")
        },
        {
            value: GroupGroupModelJoinPolicyChoices.Approve,
            label: t("group.join.approve", "Seulement après approbation")
        },
        {
            value: GroupGroupModelJoinPolicyChoices.Open,
            label: t("group.join.open", "Tout le monde")
        },
    ]

    const handleClose = () => {
        navigate("..")
    }

    const handleChange = (field: string, value: any) => {
        setData((prev: any) => ({...prev, [field]: value}))
        if (data) {
            update({
                hid: data.hid,
                [field]: value
            })
        }
    }

    return (
        <FullScreenDialog
            title={t("group.settings.privacy", "Confidentialité")}
            onClose={handleClose}
        >
            <Stack spacing={2}>
                <Paper variant="outlined" sx={{p: 2}}>
                    <List subheader={<Typography
                        variant="subtitle1">{t("group.settings.privacy.visibility_title", "Qui peut voir ce groupe")}</Typography>}>
                        {visibilityChoices.map(c => <ListItem key={c.value}><FormControlLabel
                            control={
                                <Radio
                                    checked={data?.visibility === c.value}
                                    onChange={() => handleChange("visibility", c.value)}
                                />
                            }
                            label={c.label}
                        /></ListItem>)}
                    </List>
                </Paper>
                <Paper variant="outlined" sx={{p: 2}}>
                    <List subheader={<Typography
                        variant="subtitle1">{t("group.settings.privacy.join_title", "Qui peut rejoindre le groupe")}</Typography>}>
                        {joinChoices.map(c => <ListItem key={c.value}><FormControlLabel
                            control={
                                <Radio
                                    checked={data?.joinPolicy === c.value}
                                    onChange={() => handleChange("joinPolicy", c.value)}
                                />
                            }
                            label={t(c.label)}
                        /></ListItem>)}
                    </List>
                </Paper>
            </Stack>
            <Box marginTop={2}>
                <Button
                    onClick={handleClose} variant="contained">{t('common.finished', "Terminé")}</Button>
            </Box>
        </FullScreenDialog>
    );
}
import {Community, OrganizationTeam} from "@profusion.media/graph";
import {Alert, AlertTitle} from "@mui/material";

export interface OrganizationTeamEmptyProps {
    team: OrganizationTeam
}

export const OrganizationTeamEmpty = (props: OrganizationTeamEmptyProps) => {
    const {team} = props

    return <Alert severity="info">
        <AlertTitle>Aucun administrateur</AlertTitle>
        Invitez un administrateur à cette organization
    </Alert>
}
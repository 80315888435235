import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useDashboardUI} from "../../../../contexts/DashboardUIContext";
import {StyledDrawer} from "@profusion.media/profusion-ui";
import {DrawerItems} from "./DrawerItems";

const DashboardDrawer = () => {
    const {drawerOpen, toggleDrawer} = useDashboardUI()

    const handleClick = (e: any) => {
        console.log(e)
    }

    return <StyledDrawer open={drawerOpen} variant="permanent">
        <Toolbar
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
            }}
        >
            <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon/>
            </IconButton>
        </Toolbar>
        <Divider/>
        <List component="nav" onClick={handleClick}>
            <DrawerItems/>
        </List>
    </StyledDrawer>
}

export default DashboardDrawer
import React, {useEffect, useState} from 'react';
import {Card, CardActions, CardContent, Stack,} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {
    CancelButton,
    Form,
    FormErrorBox,
    FullScreenDialog,
    HeadsUp,
    PhoneInput,
    SubmitButton,
    TextInput
} from "@profusion.media/profusion-ui";
import {RefreshRounded} from "@mui/icons-material";
import {AccountContactInput, useAccountContactUpdate, useConnectedUser} from "@profusion.media/profusion-iam-user";
import {useTranslation} from "react-i18next";

import {faPhone} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";

const FormContact: React.FC = () => {
    const user = useConnectedUser()

    const {t} = useTranslation()
    const navigate = useNavigate();
    const [fields, setFields] = useState<AccountContactInput>({
        email: user?.email ?? "",
        phoneNumber: user?.phoneNumber ?? "",
    })

    const [update, {loading: updating, data, validationErrors: errors}] = useAccountContactUpdate()

    useEffect(() => {
        if (user) {
            setFields({
                email: user.email,
                phoneNumber: (user.phoneNumber ?? "") as string
            })
        }
    }, [user])

    const handleValueChange = (name: string, value: string) => {
        setFields({...fields, [name]: value})
    }

    const closeDialog = () => {
        navigate('..', {replace: true});
    }

    const handleSave = async () => {
        const r = await update({input: fields})
        if (!r.validationErrors) {
            closeDialog()
        }
    };

    const handleCancel = () => {
        // Logique d'annulation ici
        setFields({
            email: '',
            phoneNumber: '',
        });
        closeDialog()
    };


    return (
        <FullScreenDialog title="Informations de contact"  onClose={closeDialog}>
            <Form onSubmit={handleSave}
                  onCancel={handleCancel}
                  errors={errors}
                  updating={updating}
                  autoClose>
                <FormErrorBox message="Des erreurs sont survenues"/>
                <Card>
                    <CardContent>
                        <Stack spacing={4}>
                            <TextInput value={fields.email}
                                       type="email"
                                       name="email"
                                       label={t("account.email", "E-mail")}
                                       onValueChange={handleValueChange}/>


                            <PhoneInput value={fields.phoneNumber ?? ""}
                                        name="phoneNumber"
                                        label={t("account.phone_number", "Téléphone")}
                                        onValueChange={handleValueChange}/>

                            <HeadsUp>
                                <FontAwesomeIcon icon={faPhone}/>
                                <Typography variant="body2">
                                    Veuillez indiquer uniquement un numéro de téléphone cellulaire, celui vous sera
                                    utile pour connecter votre compte avec votre appareil intelligent.
                                </Typography>
                            </HeadsUp>
                        </Stack>

                    </CardContent>
                    <CardActions sx={{justifyContent: 'flex-end'}}>
                        <CancelButton label='Annuler'/>
                        <SubmitButton
                            activityIcon={<RefreshRounded/>}
                            iconPosition="left"
                            label="Enregistrer"/>
                    </CardActions>
                </Card>
            </Form>
        </FullScreenDialog>
    );
};

export default FormContact;

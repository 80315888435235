import {RouteObject} from "react-router-dom"
import {actionCreatePrayerRequest, actionUpdatePrayerRequest} from "./actions";
import {PrayerRequestForm} from "./components/editor/PrayerRequestForm";
import {loadPrayerRequestDetails, RequestDetailsLoaderProps} from "./loaders/prayer_request_details";
import {PrayerRequestDetails} from "./components/details/PrayerRequestDetails";
import {actionCreatePrayerComment} from "./actions/prayer_comment_edit";
import {PrayerCommentForm} from "./components/comments/PrayerCommentForm";
import {actionAddPrayerEvent} from "./actions/prayer_add_prayed_event";
import {loadPinnedRequestList, loadPrayerRequestList} from "./loaders";
import {PrayerRequestList} from "./PrayerRequestList";
import {PinnedRequestList} from "./PinnedRequestList/PinnedRequestList";
import {loadList} from "../Groups/GroupList/loaders";
import {PrayerStatsDialog} from "./components/stats/PrayerStatsDialog";


export const prayerGroupRouter: RouteObject[] = [
    {
        path: "prayer",
        loader: (props) => loadPrayerRequestList(props.params.hid ?? ""),
        element: <PrayerRequestList/>,
        handle: {
            tab: "1"
        },
        children: [
            {
                path: "add",
                element: <PrayerRequestForm/>,
                id: "groupPrayerRequestCreate",
                action: (props) => actionCreatePrayerRequest(props)
            },
            {
                path: ":rhid",
                element: <PrayerRequestDetails/>,
                id: "groupPrayerRequestDetails",
                loader: (props: RequestDetailsLoaderProps) => loadPrayerRequestDetails(props),
                children: [
                    {
                        path: "edit",
                        element: <PrayerRequestForm/>,
                        id: "groupPrayerRequestEdit",
                        action: (props) => actionUpdatePrayerRequest(props)
                    },
                    {
                        path: "event/prayed",
                        action: (props) => actionAddPrayerEvent(props),
                    },
                    {
                        path: "comment/add",
                        element: <PrayerCommentForm/>,
                        id: "groupPrayerCommentCreate",
                        action: (props) => actionCreatePrayerComment(props)
                    },
                    {
                        path: "stats",
                        element: <PrayerStatsDialog/>,
                        id: "groupPrayerStats",
                    },
                ]
            }
        ]
    },

]

export const prayerGlobalRouter: RouteObject[] = [
    {
        path: "favorites",
        loader: (props) => loadPinnedRequestList(),
        element: <PinnedRequestList/>,
        handle: {
            "list": "favorites"
        }
    },
    {
        path: "request/add",
        loader: () => loadList("mine"),
        element: <PrayerRequestForm />,
    }
]
import {Box, ListItem, ListItemAvatar} from "@mui/material";
import {faCommentPlus} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {startTransition} from "react";

export interface PrayerCommentAddButtonProps {
    url: string
    isFirst?: boolean
}

export const PrayerCommentAddButton = (props: PrayerCommentAddButtonProps) => {
    const {url, isFirst} = props
    const navigate = useNavigate()
    const {t} = useTranslation()

    const onClick = () => {
        startTransition(() => {
            navigate(url)
        })
    }
    return <ListItem
        onClick={_ => onClick()}>
        <ListItemAvatar>
            <FontAwesomeIcon
                color="#888"
                icon={faCommentPlus}
                size="2x"/>
        </ListItemAvatar>
        <Box flex={1}
             padding={2}
             borderRadius={1}
             sx={{
                 backgroundColor: "#fff",
                 borderColor: "#ddd",
                 borderStyle: "solid",
                 borderWidth: 1
             }}
        >
            <Typography fontStyle="italic">
                {isFirst
                    ? t("prayer.comment.button.addfirst", "Soyez le premier à commenter")
                    : t("prayer.comment.button.add", "Ajouter un commentaire")}</Typography>
        </Box>
    </ListItem>
}
import {Group} from "@profusion.media/profusion-core-group"
import {Container, Drawer, IconButton, ListItem} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faGear, faLock, faTrash, faSignOut} from "@fortawesome/pro-regular-svg-icons";
import React, {useState} from "react";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from "@mui/material/ListItemIcon";
import {useNavigate} from "react-router-dom";

export interface GroupAppBarSettingsProps {
    group: Group
}

export const GroupAppBarSettings = (props: GroupAppBarSettingsProps) => {
    const {group} = props
    const [open, setOpen] = useState(false)

    const handleSettings = async () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    }

    return <>
        <IconButton onClick={handleSettings}>
            <FontAwesomeIcon color="#f0f0f0" icon={faGear}/>
        </IconButton>

        <Drawer anchor="bottom"
                open={open}
                onClose={handleClose}
        >
            {group.folder === "own"
                ? <GroupBarSettingsMine group={group} />
                : <GroupBarSettingsShared group={group} />}
        </Drawer>
    </>
}

const GroupBarSettingsMine = (props: GroupAppBarSettingsProps) => {
    const {group} = props
    const navigate = useNavigate()

    const handleEdit = () => {
        navigate("edit")
    }

    const handlePrivacy = () => {
        navigate("privacy")
    }

    const handleDelete = () => {
        navigate("delete")
    }

    return <Container maxWidth="lg" disableGutters>
        <List
            sx={{pl: 2, width: '100%', bgcolor: 'background.paper'}}
            subheader={<ListSubheader>Paramètres</ListSubheader>}
        >
            <ListItem onClick={handleEdit}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faEdit} fixedWidth/>
                </ListItemIcon>
                <ListItemText primary="Mettre à jour"/>
            </ListItem>
            <ListItem onClick={handlePrivacy}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faLock} fixedWidth/>
                </ListItemIcon>
                <ListItemText primary="Confidentialité"/>
            </ListItem>
            <ListItem sx={{color: "#ff0000"}} onClick={handleDelete}>
                <ListItemIcon>
                    <FontAwesomeIcon color="#ff0000" icon={faTrash} fixedWidth/>
                </ListItemIcon>
                <ListItemText primary="Supprimer le groupe"/>
            </ListItem>
        </List>
    </Container>
}


const GroupBarSettingsShared = (props: GroupAppBarSettingsProps) => {
    const {group} = props
    const navigate = useNavigate()

    const handleLeave = () => {
        navigate("leave")
    }

    return <Container maxWidth="lg" disableGutters>
        <List
            sx={{pl: 2, width: '100%', bgcolor: 'background.paper'}}
            subheader={<ListSubheader>Paramètres</ListSubheader>}
        >

            <ListItem sx={{color: "#ff0000"}} onClick={handleLeave}>
                <ListItemIcon>
                    <FontAwesomeIcon color="#ff0000" icon={faSignOut} fixedWidth/>
                </ListItemIcon>
                <ListItemText primary="Quitter le groupe"/>
            </ListItem>
        </List>
    </Container>
}
import {useNavigate} from "react-router-dom";

export const useNavigateRequestDetails = (groupHid: string)  => {
    const navigate = useNavigate()
    return (requestHid: string) => navigate(`/groups/${groupHid}/prayer/${requestHid}`, {
        state: {
            from: window.location.pathname
        }
    })
}
export const useNavigateRequestEdit = (groupHid: string)  => {
    const navigate = useNavigate()
    return (requestHid: string) => navigate(`/groups/${groupHid}/prayer/${requestHid}/edit`)
}
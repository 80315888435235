import {useNavigate, useParams} from "react-router-dom";
import {useGroupDetails} from "@profusion.media/profusion-core-group";
import {useTranslation} from "react-i18next";
import {FullScreenDialog, WaitingPage} from "@profusion.media/profusion-ui";
import {SubscriberInvitationByEmail} from "./SubscriberInvitationByEmail";
import React, {useState} from "react";

export const SubscriberInvitation = () => {
    const {hid} = useParams<{ hid: string }>()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {data: group, loading: loadingGroup} = useGroupDetails(hid ?? "")
    const [tab, setTab] = useState<number>(1)

    const handleClose = () => {
        navigate("..", {replace: true})
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    if (loadingGroup) return <WaitingPage show={true}/>


    return <FullScreenDialog
        title={t("invitation.form.title", "Inviter un ami")}
        onClose={handleClose}
    >
        {group && <SubscriberInvitationByEmail group={group} onClose={handleClose}/>}
    </FullScreenDialog>
}
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {Box, IconButton, Toolbar, useTheme} from "@mui/material";
import {useDrawerMenu, UserAvatar} from "@profusion.media/profusion-ui";
import Typography from "@mui/material/Typography";
import {faMultiply} from "@fortawesome/pro-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const DashboardDrawerHeader = () => {
    const user = useConnectedUser()
    const theme = useTheme()
    const {drawerOpen, toggleDrawer, setDrawerOpen} = useDrawerMenu()

    if (!drawerOpen) {
        return <Toolbar></Toolbar>
    }

    return <Box
        position="relative"
        height="96px"
        marginBottom="96px"
        sx={{backgroundColor: theme.palette.primary.main}}>
        <IconButton onClick={toggleDrawer} sx={{position: "absolute", top: 10, right: 10}}>
            <FontAwesomeIcon icon={faMultiply} color="#fff" fixedWidth />
        </IconButton>
        <Box display="flex"
             flexDirection="column"
             alignItems="center"
             textAlign="center"
             position="absolute"
             bottom={-96 + 9}
             left={0}
             right={0}
        >
            <UserAvatar userName={user.profile?.displayName}
                        avatar={user.profile?.avatar?.mediumUrl}
                        size={96}
            />
            <Typography>
                {user.firstName} {user.lastName}
            </Typography>
            <Typography variant="caption">
                {user.profile?.displayName}
            </Typography>
        </Box>

    </Box>
}
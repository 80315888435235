import {useState} from "react";
import {MutationUpdateOrganizationArgs, Organization} from "@profusion.media/graph";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {OrganizationForm} from "./OrganizationForm";
import {emptyOrganizationInput} from "../../typings";
import {Await, useActionData, useNavigate, useNavigation, useRouteLoaderData, useSubmit} from "react-router-dom";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {OrganizationLoaderReturn} from "@organizations/loaders/organizationDetailsLoader";

export const OrganizationUpdate = () => {
    const submit = useSubmit()
    const {organization} = useRouteLoaderData("organizationDetails") as OrganizationLoaderReturn
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()
    const [data, setData] = useState<MutationUpdateOrganizationArgs>({
        organization: {...emptyOrganizationInput, hid: ""},
    });
    const navigate = useNavigate()
    const loading = navigation.state === "loading"

    const _normalize = (organization: Organization): MutationUpdateOrganizationArgs["organization"] => {
        const address = organization.address as Required<any>
        return {
            hid: organization.hid as string,
            name: organization.name,
            shortName: organization.shortName,
            leaderLastName: organization.leaderLastName,
            leaderFirstName: organization.leaderFirstName,
            email: organization.email,
            phoneNumber: organization.phoneNumber,
            website: organization.website,
            address: {
                street: address.street,
                city: address.city,
                province: address.province,
                postalCode: address.postalCode,
                country: address.country,
                displayName: address.displayName,
                visibility: address.visibility ?? "public"
            },

        }
    }

    const handleSubmit = async (organization: MutationUpdateOrganizationArgs["organization"]) => {
        const newData = {...data, organization}
        setData(newData)
        submit(newData, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        navigate("/su/organizations")
    }

    return <FullScreenDialog title={`Mettre à jour ${data.organization.name}`} onClose={handleCancel}>
        <Await resolve={organization}>
            {({data}) =>
                <OrganizationForm<MutationUpdateOrganizationArgs["organization"]>
                    organization={_normalize(data)}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    loading={loading}
                    errors={validationErrors}
                />}
        </Await>
    </FullScreenDialog>
}
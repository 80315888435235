import * as React from 'react';
import {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CopyrightLine from "../../components/utils/CopyrightLine";
import {useTranslation} from "react-i18next";
import {useAuthentication, useProfusionSession} from "@profusion.media/profusion-core";
import {Outlet, useNavigate, useSearchParams} from "react-router-dom"
import {PasswordInput, TextInput} from "@profusion.media/profusion-ui"
import Divider from "@mui/material/Divider";
import {Alert, Stack} from "@mui/material";

const theme = createTheme();

export default function LoginPage() {
    const {t} = useTranslation()
    const {login, is_authenticated} = useAuthentication()
    const [error, setError] = useState<boolean>(false)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const session = useProfusionSession()
    const xt = searchParams.get("xt") ?? ""
    const next = searchParams.get("next") ?? ""
    const [data, setData] = useState({username: "", password: "", exchangeToken: xt})

    const connectSession = () => {
        session.connectTokenUpdate(xt ? xt : "").then(_r => {
            if (_r.success) {
                window.location.href = _r.nextUrl
            }
        })
    }

    useEffect(() => {
        if (xt !== "" && is_authenticated) {
            connectSession()
        }
    }, [xt, is_authenticated]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        login(data).then((response: any) => {
            if (xt) {
                connectSession()
            } else {
                if (next) {
                    window.location.replace(next)
                } else {
                    if (response.redirectUrl) {
                        window.location.replace(response.redirectUrl)
                    } else {
                        navigate("/groups")
                    }
                }
            }
        }).catch((reason: any) => {
            setError(true)
        })
    };

    const goRegistrationForm = () => {
        navigate("/register?rel=login"+(next ? "&next="+next : "")+(xt ? "&xt="+xt : ""))
    }

    const onChange = (name: string, value: string) => {
        setData((prev: any) => ({...prev, [name]: value}))
    }

    return (
        <ThemeProvider theme={theme}>
            <Outlet/>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("login.title", "Connexion")}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextInput
                            margin="normal"
                            required
                            label={t("login.username", "Utilisateur")}
                            name="username"
                            autoComplete="email"
                            autoFocus
                            value={data.username}
                            onValueChange={onChange}
                        />
                        <PasswordInput label={t("login.password", "Mot de passe")}
                                       value={data.password}
                                       name="password"
                                       onValueChange={onChange}
                        />


                        <Grid container>
                            <Grid item xs>
                                <Link href={"/login/reset-password?e=" + encodeURIComponent(data.username)} variant="body2">
                                    {t("login.forgot_password", "Mot de passe oublié ?")}
                                </Link>
                            </Grid>
                        </Grid>

                        {error && <Alert variant="filled" severity="error">
                            {t("login.message.errors", "Identifiant ou mot de passe incorrect")}
                        </Alert>}

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            {t("login.submit", "Me connecter")}
                        </Button>
                    </Box>
                </Box>
                <Divider sx={{my: 4}}>
                    {t("login.or_label", "OU")}
                </Divider>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{textAlign: "center"}}>
                    <Typography>{t("login.new_account_description", "Je n'ai pas encore de compte")}</Typography>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={_ => goRegistrationForm()}>
                        {t("login.new_account_button", "Créer un compte")}
                    </Button>
                </Stack>
                <CopyrightLine sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}
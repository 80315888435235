import {PrayerEvent} from "@profusion.media/profusion-core-prayer"
import {AvatarGroup, ListItem, ListItemAvatar} from "@mui/material";
import List from "@mui/material/List";
import {PrayerPrayedListItem} from "./PrayerPrayedListItem";
import {UserAvatar} from "@profusion.media/profusion-ui";
import ListItemText from "@mui/material/ListItemText";
import {getFirstOccurrences} from "../../utils";

export interface PrayerPrayedListProps {
    prayers: PrayerEvent[]
    flat?: boolean
}

export const PrayerPrayedList = (props: PrayerPrayedListProps) => {

    const uniquePrayers = getFirstOccurrences(props.prayers)


    if (props.flat || uniquePrayers.length <= 3) {
        return <List>
            {uniquePrayers.map(c => <PrayerPrayedListItem key={c.hid} prayer={c} count={c.count}/>)}
        </List>
    }


    const firstAvatars = uniquePrayers.slice(0, 3);
    const listDisplayNames = uniquePrayers.map(c => c.createdBy.displayName).join(", ")


    return <ListItem dense>
        <ListItemAvatar>
            <AvatarGroup max={4}
                         slotProps={{
                             additionalAvatar: {
                                 sx: {
                                     width: 22,
                                     height: 22,
                                     fontSize: 10,
                                     backgroundColor: "#f0f0f0",
                                     color: "#888"
                                 }
                         }}}
                         total={uniquePrayers.length}
                         spacing={7}
            >
                {firstAvatars.map(c => <UserAvatar avatar={c.createdBy?.avatar?.smallUrl}
                                                    userName={c.createdBy.displayName}
                                                    size={22}
                />)}
            </AvatarGroup>
        </ListItemAvatar>
        <ListItemText
            primary={listDisplayNames + " et " + (props.prayers.length - 3) + " autres ont prié"}
            primaryTypographyProps={{
                variant: "caption"
            }}
        />
    </ListItem>
}
import {gql} from "@apollo/client";

export const COMMUNITY_FRAGMENT = gql`
    fragment COMMUNITY_FRAGMENT on Community {
        hid
        isPrivate
        isSystem
        shortDescription
        description
        language
        name
        handle
        color
        childrenCount
        roles {
            roles
            isDefault
            organizationHid
        }
    }`
import {Suspense} from "react";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {Await, useNavigate, useNavigation, useSubmit} from "react-router-dom";
import {Avatar, Button, Card, CardActions, CardContent, CardHeader} from "@mui/material";
import {Warning} from "@mui/icons-material"
import {red} from "@mui/material/colors";
import {useOrganizationDetailsLoader} from "@organizations/loaders/organizationDetailsLoader";

export const OrganizationDeleteDialog = () => {
    const submit = useSubmit()
    const {organization} = useOrganizationDetailsLoader("organizationDetailsSU")
    const navigation = useNavigation()

    const navigate = useNavigate()
    const loading = navigation.state === "loading"


    const handleSubmit = async (hid: string) => {
        const newData = {hid}

        submit(newData, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        navigate("..")
    }

    return <Suspense>
        <Await resolve={organization}>
            {({data}) =>
                <FullScreenDialog title={`Suppression de ${data.name}`} onClose={handleCancel}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar sx={{backgroundColor: red[500]}}>
                                    <Warning/>
                                </Avatar>
                            }
                            title="Confirmation de suppression"
                            subheader="Êtes-vous sûr de vouloir supprimer cette organisation?"
                        />
                        <CardContent>
                            <strong>Attention !</strong>
                            <p>Cette opération est irréversible est causera la destruction :</p>
                            <ul>
                                <li>des données de l'organisation</li>
                                <li>des droits d'accès des administrateurs</li>
                                <li>des communautés et groupes de l'organisation</li>
                            </ul>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" onClick={handleCancel}>
                                Annuler
                            </Button>

                            <Button variant="contained" color="error" onClick={() => handleSubmit(data.hid)}>
                                Confirmer
                            </Button>
                        </CardActions>
                    </Card>

                </FullScreenDialog>
            }
        </Await>
    </Suspense>
}
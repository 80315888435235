import {Box, Button, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";


export const CompletedInvitationAlert = () => {
    const navigate = useNavigate()

    const handleCancel = async () => {

        navigate("..")
    }

    return <Stack direction="row-reverse" spacing={2} sx={{alignItems: "center"}}>
        <Button size="small" color="error"
                sx={{alignSelf: "flex-end"}}
                onClick={handleCancel}
        >Annuler</Button>
        <Box>Cette personne participe déjà à votre groupe</Box>
    </Stack>
}
import React from "react"
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import ErrorPage from "./ErrorPage";
import {LogoutPage} from "../screens/logout/LogoutPage";
import {HomePage} from "../screens/homepage/HomePage";
import LoginPage from "../account/screens/login/LoginPage";
import PasswordLost from "../account/screens/passwordLost/PasswordLost";
import {actionSendResetRequest, actionUpdateResetPassword} from "../account/screens/passwordLost/action";
import PasswordUpdate from "../account/screens/passwordLost/PasswordUpdate";
import AccountRouter from "../account/screens/dashboard/AccountRouter";
import GroupsRouter from "../groups/GroupsRouter";
import {invitationLoader} from "./loaders";
import RegisterPage from "../account/screens/register/RegisterPage";
import {DownloadApp} from "../screens/DownloadApp/DownloadApp";
import {loadPinnedRequestList} from "../groups/Prayers";
import {PinnedRequestList} from "../groups/Prayers/PinnedRequestList/PinnedRequestList";
import {loadAllOverviews} from "../groups/Groups/GroupDetails/loaders";
import {SuperadminRouter} from "../superadmin/SuperadminRouter";
import {JoinCommunity} from "../screens/Join/JoinCommunity";
import {TestPdfReader} from "../library/reader/test"

const MainRouter = () => {

    const router = createBrowserRouter([
        {
            path: "/",
            errorElement: <ErrorPage/>,
            element: <HomePage/>
        },
        {
            path: "/library/test",
            element: <TestPdfReader />
        },
        {
            path: "/login",
            errorElement: <ErrorPage/>,
            element: <LoginPage/>,
            children: [
                {
                    path: "reset-password",
                    element: <PasswordLost/>,
                    action: (props) => actionSendResetRequest(props)
                },
                {
                    path: "update-password",
                    element: <PasswordUpdate/>,
                    action: (props) => actionUpdateResetPassword(props)
                },
            ],
        },
        {
            path: "/register",
            errorElement: <ErrorPage/>,
            element: <RegisterPage/>
        },
        {
            path: "/logout",
            errorElement: <ErrorPage/>,
            element: <LogoutPage/>
        },
        {
            path: "/account",
            children: AccountRouter
        },
        {
            path: "/groups",
            id: "groupsRoot",
            loader: loadAllOverviews,
            children: GroupsRouter
        },
        {
          path: "/su",
          children: SuperadminRouter
        },
        {
          path: "/join/community/:community",
          element: <JoinCommunity />
        },
        {
            path: "/invitation/:hid",
            loader: invitationLoader
        },
        {
            path: "/downloadApp",
            element: <DownloadApp/>
        },
        {
            path: "favorites",
            loader: (props) => loadPinnedRequestList(),
            element: <PinnedRequestList/>,
            handle: {
                "list": "favorites"
            }
        },
    ])

    return <RouterProvider router={router}/>
}

export default MainRouter
import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {Autocomplete, Box, InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {SearchOrganizationResult} from "@profusion.media/graph"
import {SearchOptionValue} from "./SearchOptionValue";
import {SEARCH_QUERY} from "./search_query";

export interface SearchOrganizationInputProps {
    onChange: (value: SearchOrganizationResult) => void
}

const SearchOrganizationInput = (props: SearchOrganizationInputProps) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<SearchOrganizationResult[]>([]);
    const [searchItems, {loading, data}] = useLazyQuery(SEARCH_QUERY, {
        fetchPolicy: 'no-cache',
        context: {
            use_private: true
        }
    });

    useEffect(() => {
        if (inputValue.length >= 3) {
            searchItems({
                variables: {
                    search: inputValue
                }
            });
        }
    }, [inputValue, searchItems]);

    useEffect(() => {
        if (data && data.searchOrganization) {
            console.log(data.searchOrganization)
            setOptions(data.searchOrganization);
        }
    }, [data]);

    const handleInputChange = (event: any, value: React.SetStateAction<string>) => {
        setInputValue(value);
    };

    return (
        <Autocomplete<SearchOrganizationResult>
            options={options}
            inputValue={inputValue}
            getOptionLabel={option => option.name || ""}
            autoHighlight
            onInputChange={handleInputChange}
            loading={loading}
            renderOption={(params, option) => <Box><SearchOptionValue showClear={false} value={option} onSelect={props.onChange}/></Box>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default SearchOrganizationInput;

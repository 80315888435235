import React, {useEffect, useState} from "react";
import {Group} from "@profusion.media/profusion-core-group"
import {PrayerRequestCreateInput, PrayerRequestUpdateInput} from "@profusion.media/profusion-core-prayer";
import {
    CancelButton,
    DateInput,
    Form,
    FormErrorBox,
    FullScreenDialog,
    SubmitButton,
    TextInput
} from "@profusion.media/profusion-ui";
import {Card, CardActions, CardContent, Checkbox, FormControlLabel, Stack} from "@mui/material";
import {
    useActionData,
    useLoaderData,
    useNavigate,
    useNavigation,
    useParams,
    useRouteLoaderData,
    useSubmit
} from "react-router-dom";
import {ValidationErrors} from "@profusion.media/profusion-core";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../../../styles/editor.css'
import {useTranslation} from "react-i18next";
import {LoadPrayerRequestDetailsReturn} from "../../loaders";
import {LoadListReturn} from "../../../Groups/GroupList/loaders";
import {GroupSelectInput} from "../../../Groups/components/GroupSelectInput";

export const PrayerRequestForm = () => {
    const {hid: group, rhid} = useParams()
    const {t} = useTranslation()
    const submit = useSubmit()
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()
    const requestData = useRouteLoaderData("groupPrayerRequestDetails") as LoadPrayerRequestDetailsReturn
    const groupsData = useLoaderData() as LoadListReturn
    const [showExpiration, setShowExpiration] = useState<boolean>(false)
    const [groups, setGroups] = useState<Group[] | undefined>()

    const navigate = useNavigate()
    const loading = navigation.state === 'submitting'

    const emptyRequest: PrayerRequestCreateInput & PrayerRequestUpdateInput = {
        title: "",
        text: "",
        groupHid: group ?? "",
        dateExpiration: "",
        hid: ""
    }

    const [data, setData] = useState<PrayerRequestCreateInput & PrayerRequestUpdateInput>(emptyRequest)

    useEffect(() => {
        if (rhid && requestData) {
            requestData.prayerRequest.then(({data: request}) => {
                setData(request)
                setShowExpiration(!!request.dateExpiration)
            })
        }
    }, [rhid, requestData]);
    useEffect(() => {
        if (!group && !rhid && groupsData) {
            groupsData.groups.then(({data: _d}) => setGroups(_d))
        }
    }, []);

    const onChange = (name: string, value: any) => {
        setData(prev => ({...prev, [name]: value}))
    }

    const handleExpirationCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowExpiration(event.target.checked);
        if (!event.target.checked || !data.dateExpiration) {
            onChange('dateExpiration', '');
        }
    }

    const handleSubmit = () => {
        submit(data, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        navigate(-1)
    }

    return <FullScreenDialog title="Nouvelle requête de prière"
                             onClose={handleCancel}>
        <Form onSubmit={handleSubmit}
              onCancel={handleCancel}
              errors={validationErrors}
              updating={loading}
        >
            <FormErrorBox message="Des erreurs sont survenues"/>
            <Card>
                <CardContent>
                    <Stack spacing={4}>
                        <TextInput name="title"
                                   value={data.title}
                                   onValueChange={onChange}
                                   label="Titre"
                        />
                        {groups &&
                            <GroupSelectInput
                                value={data?.groupHid ?? ""}
                                groups={groups}
                                onSelect={selected => onChange("groupHid", selected)}/>}
                        <FormControlLabel
                            control={<Checkbox
                                checked={showExpiration}
                                onChange={handleExpirationCheckboxChange}
                                inputProps={{'aria-label': 'controlled'}}
                            />}
                            label={t("prayer.request.form.expiration.label", "Cette requête a une échéance")}/>


                        {showExpiration && (
                            <DateInput
                                name="dateExpiration"
                                value={data.dateExpiration}
                                onValueChange={onChange}
                            />
                        )}

                        <ReactQuill
                            placeholder={t("prayer.request.form.text.placeholder", "Écrivez votre requête ici") as string}
                            value={data.text}
                            onChange={value => onChange("text", value)}
                            theme="snow"
                            style={{minHeight: 400}}
                        />

                    </Stack>
                </CardContent>
                <CardActions sx={{flexDirection: "row-reverse"}}>
                    <SubmitButton/>
                    <CancelButton/>
                </CardActions>
            </Card>
        </Form>
    </FullScreenDialog>
}
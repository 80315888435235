import {defer} from "react-router-dom"
import profusion from "../../../config/profusion";
import {loadPrayerRequestList as _loader,
loadPinnedRequestList as _loaderPinned} from "@profusion.media/profusion-core-prayer"

export type LoadPrayerRequestListReturn = {
    prayerRequests: ReturnType<typeof loadPrayerRequestList>,
    groupHid: string
}

export const loadPrayerRequestList = (groupHid: string) => {
    return defer({prayerRequests: _loader(profusion, groupHid), groupHid})
}

export type LoadPinnedRequestListReturn = {
    prayerRequests: ReturnType<typeof loadPrayerRequestList>,
}

export const loadPinnedRequestList = () => {
    return defer({prayerRequests: _loaderPinned(profusion)})
}

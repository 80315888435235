import React from 'react';
import { Avatar, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';

const AvatarContainer = styled('div')<{ width?: number | string; height?: number | string }>(
    ({ width = 60, height = 60 }) => ({
        position: 'relative',
        display: 'inline-block',
        overflow: 'hidden',
        borderRadius: '50%',
        borderWidth: 4,
        borderColor: "#fff",
        borderStyle: "solid",
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',  // Adding light shadow
        width: width,
        height: height
    })
);

const AvatarImage = styled(Avatar)<{ width?: string | number; height?: string | number }>(
    ({ width = '100%', height = '100%' }) => ({
        width: width,
        height: height,
    })
);

const Overlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 20,
    background: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    opacity: 0.45,
    transition: 'opacity 0.3s',
    [`&:hover`]: {
        background: theme.palette.primary.light,
        opacity: 0.85,
    },
}));

const CameraIcon = styled(FontAwesomeIcon)({
    fontSize: 17,
});

interface AvatarWithOverlayProps {
    imageUrl: string;
    width?: string | number;      // Add width as an optional prop
    height?: string | number;     // Add height as an optional prop
}

export const AvatarWithOverlay: React.FC<AvatarWithOverlayProps> = ({ imageUrl, width, height }) => {    // Include width and height in the component's props
    return (
        <AvatarContainer width={width} height={height}>
            <AvatarImage src={imageUrl} alt="Avatar" />
            <Overlay>
                <CameraIcon icon={faCamera} />
            </Overlay>
        </AvatarContainer>
    );
};
import profusion from "../../../config/profusion"
import {ActionFunctionArgs, redirect} from "react-router-dom"
import {executeMutation} from "@profusion.media/profusion-core";
import {
    ORGANIZATION_DELETE_MUTATION
} from "../schema/mutations/organiztion_delete_mutation";
import {
    MutationDeleteOrganizationArgs,
    OrganizationDeleteMutation,
} from "@profusion.media/graph";
import {ORGANIZATION_LIST_QUERY} from "../schema/queries/organization_list";


export const actionOrganizationDelete = async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as MutationDeleteOrganizationArgs
    const response = await executeMutation<OrganizationDeleteMutation, MutationDeleteOrganizationArgs>(
        profusion,
        ORGANIZATION_DELETE_MUTATION,
        data, {
            isPrivate: true,
            refetchQueries: [{
                query: ORGANIZATION_LIST_QUERY,
                context: {
                    use_private: true
                }
            }]
        })
    if (response.validationErrors) {
        return response.validationErrors
    }
    return redirect(`/su/organizations/`)
}

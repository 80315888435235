import {Await, useNavigate, useRouteLoaderData} from "react-router-dom";
import {loadHomeDataReturn} from "../Groups/GroupList/loaders";
import {Button, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText} from '@mui/material';
import {FullScreenDialog, UserAvatar} from "@profusion.media/profusion-ui";
import {PublicInvitation} from "@profusion.media/profusion-core-group";

export const PendingInvitationsList = () => {
    const {pendingInvitations} = useRouteLoaderData("homeScreen") as loadHomeDataReturn
    const navigate = useNavigate()
    const handleClose = () => {
        navigate("..")
    }
    const goInvitation = (hid: string) => {
        navigate(`/invitation/${hid}`)
    }

    return <FullScreenDialog
        onClose={handleClose}
        title={"Invitations en attente"}>
        <List>
            <Await resolve={pendingInvitations}>
                {({data: invitations}) => invitations.map((invitation: PublicInvitation) => (
                    <ListItem key={invitation.hid}
                              onClick={() => goInvitation(invitation.hid)}>
                        <ListItemAvatar>
                            <UserAvatar avatar={invitation.invitedBy?.avatar?.smallUrl}
                                        userName={invitation.invitedBy?.displayName ?? ""}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={invitation.invitedBy?.displayName}
                            secondary={invitation.subject}
                        />
                        <ListItemSecondaryAction>
                            <Button variant="outlined"
                                    onClick={() => goInvitation(invitation.hid)}>
                                Répondre</Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}

            </Await>
        </List>
    </FullScreenDialog>

}
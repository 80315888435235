import {Organization} from "@profusion.media/graph";
import {IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import EditIcon from "@mui/icons-material/Edit";
import {useNavigate} from "react-router-dom";
import {WhiteBox} from "../../../../../components/WhiteBox";

export interface OrganizationDetailsHeaderProps {
    organization: Organization;
}

export const OrganizationDetailsHeader = (props: OrganizationDetailsHeaderProps) => {
    const {organization} = props
    const navigate = useNavigate()

    return <WhiteBox><List>
        <ListItem>
            <ListItemAvatar>
                <UserAvatar avatar={organization.profile?.avatar?.smallUrl} userName={organization.name}/>
            </ListItemAvatar>
            <ListItemText primary={organization.name}/>
            <ListItemSecondaryAction>
                <IconButton onClick={() => navigate("edit")}>
                    <EditIcon/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    </List>
    </WhiteBox>
}
import {MutationGroupCreateArgs, MutationGroupUpdateArgs} from "@profusion.media/profusion-core-group";
import React, {useState} from "react";
import {CancelButton, Form, FormErrorBox, LanguageInput, SubmitButton, TextInput} from "@profusion.media/profusion-ui";
import {Card, CardActions, CardContent, Stack} from "@mui/material";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {AvatarEditor} from "@profusion.media/profusion-ui-image-uploader";
import ReactQuill from "react-quill";
import {useTranslation} from "react-i18next";

type GroupSettingsInputArgs = MutationGroupUpdateArgs | MutationGroupCreateArgs

export interface GroupSettingsFormProps {
    group: any
    mode: "create" | "update"
    onSubmit: (group: GroupSettingsInputArgs) => void
    onCancel: () => void
    loading: boolean
    validationErrors?: ValidationErrors
}

export function GroupSettingsForm(props: GroupSettingsFormProps) {
    const {group, mode, onCancel, onSubmit, loading, validationErrors} = props
    const [data, setData] = useState<GroupSettingsInputArgs>({input: group, avatar: undefined})
    const {t} = useTranslation()

    const handleSubmit = () => {
        onSubmit(data)
    }

    const onChange = (name: string, value: string) => {
        setData((prev: any) => ({...prev, input: {...prev.input, [name]: value}}))
    }

    const setAvatar = (avatar: any) => {
        setData((prev: any) => ({...prev, avatar: avatar}))
    }

    return <Form onSubmit={handleSubmit}
                 onCancel={onCancel}
                 updating={loading}
                 errors={validationErrors}
    >
        <FormErrorBox message="Des erreurs sont survenues"/>
        <Card>
            <CardContent>
                <Stack spacing={4}>
                    <TextInput name="title"
                               value={data.input.title}
                               onValueChange={onChange}
                               label={t("group.form.title", "Nom du groupe")}
                    />

                    <ReactQuill
                        placeholder={t("group.form.description", "Écrivez une description") as string}
                        value={data.input.welcome ?? ""}
                        onChange={value => onChange("welcome", value)}
                        theme="snow"
                        style={{minHeight: 400}}
                    />
                    <LanguageInput value={data.input.language}
                                   onChange={lang => onChange("language", lang)}
                    />
                    <AvatarEditor
                        image={data.avatar?.url ?? group.avatar?.mediumUrl}
                        onChange={setAvatar}/>
                </Stack>
            </CardContent>
            <CardActions sx={{flexDirection: "row-reverse"}}>
                <SubmitButton/>
                <CancelButton/>
            </CardActions>
        </Card>
    </Form>
}
import {gql} from "@apollo/client";

export const SEARCH_QUERY = gql`
    query SearchOrganization($search: String!) {
        searchOrganization(search: $search) {
            hid
            name
            shortName
            city
            avatar {
                smallUrl
                mediumUrl
                largeUrl
            }
        }
    }
`;
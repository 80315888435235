import {Box, BoxProps, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

export interface WhiteBoxProps extends Omit<BoxProps, "bgcolor" | "marginBottom" | "padding"> {
    title?: string
    icon?: any
}

export const WhiteBox = (props: WhiteBoxProps) => {
    const {children, icon, title, ...rest} = props;
    return <Box {...rest} marginBottom={1} padding={2} bgcolor="#fff">
        {title && <Stack alignItems="center" direction="row" spacing={1} mb={2}>
            {icon}
            <Typography variant="overline">{title}</Typography>
        </Stack>}
        {children}
    </Box>
}
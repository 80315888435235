import React from "react";
import {Group, GroupGroupModelVisibilityChoices} from "@profusion.media/profusion-core-group"
import {Box, CardContent, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {faLock, faLockOpen} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface GroupDetailsPrivacyInfoProps {
    group: Group
}

export const GroupDetailsPrivacyInfoHeader = (props: GroupDetailsPrivacyInfoProps) => {
    const {group} = props

    const visibilityText = group.visibility === GroupGroupModelVisibilityChoices.Private ? "Groupe privé" : "Groupe public";
    const visibilityIcon = group.visibility === GroupGroupModelVisibilityChoices.Private ? faLock : faLockOpen;

    return <CardContent>
        <Stack direction="row"
               spacing={1}
               color="#808080"
               divider={<Typography variant="caption">&bull;</Typography>}>
            <Typography variant="caption"><FontAwesomeIcon icon={visibilityIcon} /> {visibilityText}</Typography>
            <Typography variant="caption">
                {group.subscribersCount} membres
            </Typography>
        </Stack>
    </CardContent>
}
import {OrganizationCommunity} from "@profusion.media/graph";
import {Await, useNavigate} from "react-router-dom";
import {Suspense} from "react";
import List from "@mui/material/List";
import {ListItem, ListItemAvatar} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDotCircle} from "@fortawesome/pro-regular-svg-icons"
import {useOrganizationDetailsLoader} from "@organizations/loaders/organizationDetailsLoader";

export const OrganizationCommunityList = () => {
    const navigate = useNavigate()
    const {communities} = useOrganizationDetailsLoader("organizationDetailsSU")

    const goCommunity = (hid: string) => {
        navigate(`community/${hid}`)
    }

    return <Suspense>
        <Await resolve={communities}>
            {({data}) => <List>
                {data.map((c: OrganizationCommunity) => <ListItem key={c.hid}
                                                                  onClick={() => goCommunity(c.community?.hid || "")}>
                    <ListItemAvatar><FontAwesomeIcon icon={faDotCircle} color={c.community?.color}/></ListItemAvatar>
                    <ListItemText primary={c.community?.name}/>
                </ListItem>)}
            </List>}
        </Await>
    </Suspense>
}
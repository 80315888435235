import {AppBar, Box, Container, CssBaseline, Stack, Toolbar} from "@mui/material";
import React from "react";
import {DrawerMenuProvider} from "@profusion.media/profusion-ui";
import {useTranslation} from "react-i18next";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {NotificationCenter} from "../../NotificationCenter/NotificationCenter";
import {AppBarDrawerIcon} from "./drawer/AppBarDrawerIcon";
import {DashboardDrawerContent} from "./drawer/DashboardDrawerContent";
import {DashboardDrawer} from "./drawer/DashboardDrawer";
import Typography from "@mui/material/Typography";


interface DashboardLayoutProps {
    children: React.ReactNode

}

const DashboardLayout = (props: DashboardLayoutProps) => {
    const {t} = useTranslation()
    const user = useConnectedUser()

    if (!user) return <></>

    return <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar>
                <Toolbar>
                    <AppBarDrawerIcon/>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        {t("prayer.app.title", "Prière") ?? ""}
                    </Typography>

                    <Stack direction="row" spacing={4}>
                        <NotificationCenter/>
                    </Stack>
                </Toolbar>
            </AppBar>

            <DashboardDrawer>
                <DashboardDrawerContent/>
            </DashboardDrawer>

            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[50]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar/>
                <Container maxWidth="lg" sx={{mb: 4}} disableGutters>
                    {props.children as any}
                </Container>
            </Box>
        </Box>
}

export default DashboardLayout
import {PrayerEvent} from "@profusion.media/profusion-core-prayer"
import {ListItem, ListItemAvatar} from "@mui/material";
import {TimeAgo, UserAvatar} from "@profusion.media/profusion-ui";
import ListItemText from "@mui/material/ListItemText";

export interface PrayerPrayedListItemProps {
    prayer: PrayerEvent
    count?: number
}

export const PrayerPrayedListItem = (props: PrayerPrayedListItemProps) => {
    const {prayer, count} = props
    const extraInfo = count && (count > 1) ? ` ${count} fois` : ""
    return <ListItem dense>
        <ListItemAvatar sx={{justifyItems: "end"}}>
            <UserAvatar avatar={prayer.createdBy?.avatar?.smallUrl}
                        userName={prayer.createdBy.displayName}
            />
        </ListItemAvatar>
        <ListItemText primary={prayer.createdBy.displayName + " a prié"+(extraInfo ?? "")}
                      secondary={<TimeAgo date={prayer.dateCreated}/>}/>
    </ListItem>

}

import {AppBarDrawerIconProps, useDrawerMenu, UserAvatar} from "@profusion.media/profusion-ui"
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {Box} from "@mui/material";


export const AppBarDrawerIcon = () => {
    const {drawerOpen: open, toggleDrawer: onToggle, setDrawerOpen} = useDrawerMenu()

    const user = useConnectedUser()


    return <Box
        onClick={onToggle}
        sx={{
            borderRadius: '50%',
            borderStyle: "solid",
            borderColor: "#fff",
            marginRight: '36px',
            ...(open && {display: 'none'}),
        }}>
        <UserAvatar userName={user?.profile?.displayName}
                    avatar={user?.profile?.avatar?.smallUrl}/>
    </Box>
}